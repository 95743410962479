import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useDispatch } from 'react-redux';
import { updateWalletsLayers } from 'redux/reducers/walletBalance/reducer';
import DepositWallet from './DepositWallet/DepositWallet';
import DepositWalletTotals from './DepositWalletTotals/DepositWalletTotals/DepositWalletTotals';
import { EPaymentMethodTab } from './DepositWalletTotals/DepositWalletTotals/types';

const UserBalances = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);

	const { lastMessage } = useWebSocket(process.env.REACT_APP_SOCKET_URL as string);

	useEffect(() => {
		if (lastMessage?.data) {
			dispatch(updateWalletsLayers(JSON.parse(lastMessage?.data)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastMessage]);

	return (
		<div className="wallet-content-tables">
			<DepositWalletTotals activeTab={activeTab} setActiveTab={setActiveTab} />
			<div className="mt-40">
				<DepositWallet activeTab={activeTab} setActiveTab={setActiveTab} />
			</div>
		</div>
	);
};

export default UserBalances;
