export interface IBalanceBlockProps {
	title: string;
	data: any;
	type: EStatType;
	tab: string;
}

export enum EStatType {
	LIQUIDITY = 'liquidity',
	PLATFORM_BALANCES = 'platform',
	DEPOSIT_BALANCES = 'deposit',
	POOL_BALANCES = 'pool',
	WITHDRAW_BALANCES = 'withdraw',
	PENDING_FEES = 'fees',
	PROFIT_BALANCES = 'profit',
	B2C2_BALANCES = 'b2c2',
}
