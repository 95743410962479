import React, { FC, useLayoutEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getWalletsLayers } from 'redux/reducers/walletBalance/selectors';
import { useSelector } from 'react-redux';
import { ILayersData, layersFilter } from 'utils/layersFilter';
import { toFixedNumber } from '../../../../../../../services/utils/toFixedNumber';
import { roundingNumber } from '../../../../../../../services/utils/roundingNumber';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
}

/* eslint-disable @typescript-eslint/naming-convention */
const Item: FC<any> = ({ itemList, localFilter }) => {
	const [itemFilter, setItemFilter] = useState(itemList === null ? [] : itemList);
	const layers = useSelector(getWalletsLayers);

	useLayoutEffect(() => {
		if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a: any, b: any) => {
				const firstItem = a[localFilter.value];
				const secondItem = b[localFilter.value];
				return firstItem > secondItem ? -1 : 1;
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a: any, b: any) => {
				const firstItem = a[localFilter.value];
				const secondItem = b[localFilter.value];
				return firstItem < secondItem ? -1 : 1;
			});
			setItemFilter(min);
		}
	}, [itemList, localFilter]);

	return (
		<>
			{itemFilter?.length
				? itemFilter?.map((e: any) => {
						const layersData: ILayersData | Record<string, any> = layersFilter(
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							`${e?.asset_code}_eur`,
							layers,
						);

						return (
							<div key={uuidv4()} className="tr">
								<div className="td">
									<p className="td-hidden-name">Cryptocurrency</p>
									<div className="td__wrap td__wrap--column">
										<div className="coin coin--type12">
											<div className="coin__icon">
												<img
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													src={`/img/currencies/${e.asset_code.toLowerCase()}.svg`}
													alt={e.asset_code}
												/>
											</div>
											<div className="coin__text-wrap">
												<p className="coin__text">{e.asset_code.toUpperCase()}</p>
												<span className="coin__text-more">{e.asset_name}</span>
											</div>
										</div>
									</div>
								</div>
								<div className="td td--right">
									<p className="td-hidden-name">Platform Balances</p>
									<div className="td__wrap">
										<p>
											{toFixedNumber(e.balance, '', true)}
											<span>
												≈{' '}
												{layersData?.data
													? toFixedNumber(e.balance / Number(layersData?.data?.sell[1]), '', true)
													: toFixedNumber(e.balance_eur, '', true)}{' '}
												EUR
											</span>
										</p>
									</div>
								</div>
							</div>
						);
				  })
				: ''}
		</>
	);
};

export default Item;
