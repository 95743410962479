import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { useDebounce } from 'hooks/useDebounce';
import IconSvg from 'ui/Svg/IconSvg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

export interface ISearchSelectArr {
	name: string;
	value: string;
}

export interface ISearchWithSelect {
	resetValue?: boolean;
	selectArr: ISearchSelectArr[];
	handleSearch: (searchField: ISearchSelectArr, searchValue: string) => void;
	trade?: boolean;
	isResetable?: boolean;
	handleReset?: any;
	selectedValue?: string | undefined;
}

const SearchWithSelect: FC<ISearchWithSelect> = ({
	selectArr,
	handleSearch,
	resetValue = false,
	trade,
	isResetable,
	handleReset,
	selectedValue,
}) => {
	const [searchField, setSearchField] = useState<ISearchSelectArr>(selectArr[0]);
	const [opened, setOpened] = useState(false);
	const option = localStorage.getItem('option');

	const enabledSearch = useRef(false);
	const [searchValue, setSearchValue] = useState('');
	const dropdownElement = useRef(null);
	useClickOutside(dropdownElement, () => setOpened(false));
	const debouncedSearch = useDebounce(searchValue, 800);

	const handleSelect = (el: ISearchSelectArr) => {
		setSearchField(el);
		setOpened(false);
		!!searchValue && handleSearch(searchField, '');
		setSearchValue('');
		localStorage.setItem('option', el.value);

		// handleReset();
	};
	const resetSearchValue = () => {
		if (enabledSearch.current) {
			enabledSearch.current = false;
		}
		setSearchValue('');
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const toggleSelect = () => {
		setOpened(!opened);
	};

	useLayoutEffect(() => {
		!selectedValue && resetSearchValue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetValue]);

	useLayoutEffect(() => {
		if (debouncedSearch !== '' && !selectedValue) {
			enabledSearch.current = true;
		}
		if (debouncedSearch === searchValue && enabledSearch.current) {
			handleSearch(searchField, searchValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch]);

	const resetHandler = () => {
		setOpened(false);
		// setSearchValue('');
		setSearchField(selectArr[0]);
		handleReset();
	};

	useEffect(() => {
		selectedValue && setSearchValue(selectedValue);
	}, [selectedValue]);

	useEffect(() => {
		if (option && !trade) {
			setSearchField(selectArr.filter((el) => el.value === option)[0]);
		} else {
			setSearchField(selectArr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [option]);

	return (
		<>
			<div className="filter">
				{trade ? (
					<div className="input input--mb-0">
						<div className="input-wrapper">
							<input
								className="input-item input-item--type2"
								type="text"
								placeholder={searchField?.name}
								value={searchValue}
								onChange={handleChange}
							/>
						</div>
					</div>
				) : (
					<div className="search-input-wrap">
						<div className="search-input">
							<div
								ref={dropdownElement}
								className={`select select--type4 ${opened ? 'active' : ''}`}
							>
								<button type="button" className="select__current" onClick={toggleSelect}>
									{searchField?.name}
									<span className="select__current-arrow">
										<IconSvg name="keyboard-arrow-down" w="9" h="5" />
									</span>
								</button>
								<AnimatePresence>
									{opened && (
										<motion.div
											className="select__drop"
											initial={{ opacity: 0 }}
											animate={{ opacity: 1, transition: { duration: 0.3 } }}
											exit={{ opacity: 0, transition: { duration: 0.3 } }}
										>
											<div className="select__drop-scroll">
												<div className="select__drop-item">
													<ul>
														{selectArr.map((el) => (
															<li key={el.value}>
																<button type="button" onClick={() => handleSelect(el)}>
																	{el.name}
																</button>
															</li>
														))}
													</ul>
												</div>
											</div>
										</motion.div>
									)}
								</AnimatePresence>
							</div>

							<div className="input input--mb-0">
								<div className="input-wrapper">
									<input
										className="input-item input-item--type2 input-item--pr50"
										type="text"
										placeholder={`Search by ${searchField.name}`}
										value={searchValue}
										onChange={handleChange}
									/>
									{!trade && (
										<div className="input-icon input-icon--auto input-icon--right">
											<IconSvg name="search" w="16" h="16" />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{isResetable && (
				<div className="filter--right">
					<button type="button" className="btn btn-reset" onClick={resetHandler}>
						Reset
					</button>
				</div>
			)}
		</>
	);
};

export default SearchWithSelect;
