import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { roundingNumber } from 'services/utils/roundingNumber';
import {
	deleteUserReferralRequest,
	updateUserReferralFeeRequest,
} from 'redux/reducers/users/reducer';
import WarningPopUp from 'layouts-elements/PopUp/WarningPopUp/WarningPopUp';

import IconSvg from 'ui/Svg/IconSvg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { getUserLayers } from 'redux/reducers/users/selectors';
import { ILayersData, layersFilter } from 'utils/layersFilter';
import { ITradeContext } from 'redux/reducers/referrals/types';
import { IReferralTableRowProps } from '../types';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const ReferralTableRow: FC<IReferralTableRowProps> = ({
	id,
	data,
	email,
	bonusAccrued,
	tradeVolume,
	commission,
	userId,
	permission,
	referralSystem,
	setCurrentPage,
	setItemsPerPage,
	referralFee,
}) => {
	const dispatch = useDispatch();

	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [showTradeSize, setShowTradeSize] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [feeValue, setFeeValue] = useState(0);

	const layers = useSelector(getUserLayers);

	const handleOpenConfirmModal = () => {
		setOpenConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setOpenConfirmModal(false);
	};

	const handleConfirm = useCallback(() => {
		dispatch(deleteUserReferralRequest({ userId, referral_id: id }));
		setItemsPerPage(10, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id, userId, setCurrentPage]);

	const isTradeVolumesBlank = !!Object.keys(tradeVolume?.all_in_eur).length;

	const tradeVolumeMap = (obj: ITradeContext, fn: (value: string, key: string) => ReactElement) =>
		isTradeVolumesBlank && Object.entries(obj).map(([key, value]) => fn(key, value));

	const createTradeVolumeElement = (key: string, value: string) => (
		<div key={key} className="trade-size-item">
			<p>{key.toUpperCase()}</p>
			<p>{roundingNumber(value)}</p>
		</div>
	);

	const updateReferralFeeHandler = () => {
		dispatch(
			updateUserReferralFeeRequest({ user_id: id, commission: feeValue, global_user_id: userId }),
		);
	};

	useEffect(() => {
		setFeeValue(referralFee);
	}, [referralFee]);

	const tradeVolumeCodes = Object.entries(tradeVolume.all);

	const tradeVolumeEur = tradeVolumeCodes.reduce(
		(acc: Record<string, any>, item: Array<string | number>) => {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			const layersData: ILayersData | Record<string, any> = layersFilter(`${item[0]}_eur`, layers);
			return layersData?.data
				? { ...acc, [item[0]]: Number(item[1]) * Number(layersData?.data?.sell[1]) }
				: { ...acc, [item[0]]: Number(item[1]) };
		},
		{},
	);
	const totalValue = Object.values(tradeVolumeEur).reduce(
		(acc: number, value: number) => acc + value,
		0,
	);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<div className="td__wrap">
					<Link to={`/user-management/${id}/account`} className="link">
						{id}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referred User</p>
				<div className="td__wrap">
					<p>
						{data?.first_name} {data?.last_name}
					</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td__wrap">
					<Link to={`/user-management/${id}/account`} className="link link--type2">
						{email}
					</Link>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total Commission</p>
				<div className="td__wrap">
					{/* <p>{roundingNumber(bonusAccrued)} EUR</p> */}
					<p>{toFixedNumber(data?.commission, '', true)} EUR</p>
				</div>
			</div>
			<div className="td td--right" onMouseLeave={() => setShowTradeSize(false)}>
				<p className="td-hidden-name">Trade Value</p>
				<div className="td__wrap">
					<p>{toFixedNumber(totalValue, '', true)} EUR</p>
					{isTradeVolumesBlank && (
						<div className="trade-size" onMouseEnter={() => setShowTradeSize(true)}>
							<IconSvg name="layers" w="17" h="17" />
							<AnimatePresence>
								{showTradeSize && (
									<motion.div
										className="trade-size__drop"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1, transition: { duration: 0.3 } }}
										exit={{ opacity: 0, transition: { duration: 0.3 } }}
									>
										<div className="trade-size-list">
											<div className="trade-size-item trade-size-item--header">
												<p>Currency </p>
												<p>Trade Volume, EUR</p>
											</div>
											{tradeVolumeMap(tradeVolumeEur, createTradeVolumeElement)}
										</div>
									</motion.div>
								)}
							</AnimatePresence>
						</div>
					)}
				</div>
			</div>
			{/* <div className="td"> */}
			{/*	<p className="td-hidden-name">Commission</p> */}
			{/*	<p>{roundingNumber(data?.commission)} EUR</p> */}
			{/* </div> */}
			<div className="td td--right">
				<p className="td-hidden-name">Percentage %</p>
				<div className="td__wrap">
					{editMode ? (
						<input
							type="number"
							placeholder="0"
							name="fee"
							className="input-item input--table-item input-item---fullWidth input-item--type-td"
							value={feeValue}
							onChange={(e) => setFeeValue(Number(e.target.value))}
						/>
					) : (
						<p>{referralFee}</p>
					)}
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons flex-e">
					{permission && (
						<>
							{!editMode && (
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										className="btn btn--icon btn--edit"
										onClick={() => setEditMode(!editMode)}
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										className="btn btn--icon btn--delete"
										onClick={handleOpenConfirmModal}
									/>
								</div>
							)}
							{editMode && (
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										className="btn--icon btn--cancel"
										onClick={() => {
											setEditMode(!editMode);
											setFeeValue(referralFee);
										}}
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										className="btn--icon btn--check"
										onClick={updateReferralFeeHandler}
									/>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			<WarningPopUp
				open={openConfirmModal}
				closeModal={closeConfirmModal}
				title="Delete Referral"
				message="Are you sure you want to delete the referral?"
				confirmHandler={handleConfirm}
			/>
		</div>
	);
};

export default ReferralTableRow;
