/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardStore } from './types';

// ==========================================:
export const initialState: IDashboardStore = {
	depositBalance: null,
	profitBalance: null,
	poolBalance: null,
	withdrawBalance: null,
	b2c2Balance: null,
	pendingFee: null,
	platformBalance: null,
	liquidity: null,
	layers: null,
};

// ==========================================:
const dashboard = createSlice({
	name: '@@dashboard',
	initialState,
	reducers: {
		dashboardInitialState: () => {
			return initialState;
		},
		dashboardCryptoRequest: (state) => {},
		dashboardFiatRequest: (state) => {},
		getCryptoDataSuccess: (state, { payload }) => {
			const dashboardState = state;
			const {
				depositBalance,
				profitBalance,
				poolBalance,
				withdrawBalance,
				b2c2Balance,
				pendingFee,
				platformBalance,
				liquidity,
			} = payload;
			dashboardState.b2c2Balance = b2c2Balance;
			dashboardState.depositBalance = depositBalance;
			dashboardState.profitBalance = profitBalance;
			dashboardState.poolBalance = poolBalance;
			dashboardState.withdrawBalance = withdrawBalance;
			dashboardState.pendingFee = pendingFee;
			dashboardState.platformBalance = platformBalance;
			dashboardState.liquidity = liquidity;
		},
		getFiatDataSuccess: (state, { payload }) => {
			const dashboardState = state;
			const { platformBalance, pendingFee, b2c2Balance, liquidity } = payload;
			dashboardState.b2c2Balance = b2c2Balance;
			dashboardState.platformBalance = platformBalance;
			dashboardState.pendingFee = pendingFee;
			dashboardState.liquidity = liquidity;
		},
		updateDashboardLayers: (state, action: PayloadAction<Record<string, unknown>>) => {
			const newState = state;
			newState.layers = action.payload;
		},
	},
});

export default dashboard.reducer;
export const {
	dashboardInitialState,
	dashboardCryptoRequest,
	dashboardFiatRequest,
	getCryptoDataSuccess,
	getFiatDataSuccess,
	updateDashboardLayers,
} = dashboard.actions;
