import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import cn from 'classnames';
import Popup from 'reactjs-popup';
import { Field, Form, Formik } from 'formik';
import Input from 'ui/Formik/Input';
import InputTotp from 'ui/Formik/InputTotp';
import Select from 'ui/Formik/Select';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { changeBankAccountsUserId } from 'redux/reducers/bankAccounts/reducer';
import { addDepositCryptoRequest } from 'redux/reducers/transactions/reducer';
import SelectDate from 'ui/SelectDate';
import { IGetCurrencyUserPayload } from 'redux/reducers/currency/types';
import UserSearch from './UserSearch/UserSearch';
import { IPopUp } from '../types';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';
import { ICurrencySelectItem } from '../../../ui/Formik/Select/CurrencySelect/types';
import CreateDepositAccountPopUp from '../CreateDepositAccount/CreateDepositAccount';
import { clearError } from '../../../redux/reducers/errors/reducer';
import { IAddDepositCryptoWorker } from '../../../redux/reducers/transactions/types';
import {
	clearUserWalletAddresses,
	getUserWalletAddressesRequest,
} from '../../../redux/reducers/walletAddresses/reducer';
import { getUserWalletAddressData } from '../../../redux/reducers/walletAddresses/selectors';
import { EApiCryptoTransactionStatus } from '../../../services/api/transactions/types';
import NetworkSelect from '../../../ui/Formik/Select/NetworkSelect/NetworkSelect';

export interface IAddCryptoDepositForm {
	date: string;
	asset_code: string;
	network: string;
	indicated_amount: string;
	fee: string;
	status: string;
	tx_hash: string;
	note: string;
	twoFa: string;
}

const AddCryptoDepositTransaction: FC<IPopUp> = ({ open, closeModal, buttonClick }) => {
	const dispatch = useDispatch();
	const userWalletAddressData = useSelector(getUserWalletAddressData);
	const [reset, setReset] = useState(false);
	const [userId, setUserId] = useState('');
	const [resetNetwork, setResetNetwork] = useState(false);
	const handleChangeUserId = (currentUserId: string) => {
		setUserId(currentUserId);
		const params: IGetCurrencyUserPayload = {
			apiParams: {
				userId: String(currentUserId),
				value: 'fiat',
			},
		};
		// dispatch(currencyValueRequest(params));
	};

	const [indicatedAmount, setIndicatedAmount] = useState(0);
	const [fee, setFee] = useState(0);
	const [netAmount, setNetAmount] = useState(0);
	const [netAmountError, setNetAmountError] = useState('');

	const [createDepositOpen, setCreateDepositOpen] = useState(false);

	const [walletAddress, setWalletAddress] = useState('');
	const [selectedCurrency, setSelectedCurrency] = useState<ICurrencySelectItem | null>(null);
	const [selectedNetwork, setSelectedNetwork] = useState<any>(null);

	const handleCloseCreateDeposit = () => {
		setCreateDepositOpen(false);
	};
	const handleOpenCreateDeposit = () => {
		setCreateDepositOpen(true);
	};

	const changeIndicatedAmount = (value: string) => {
		setIndicatedAmount(Number(value));
		if (Number(value) < fee) {
			setNetAmountError('Gross amount can`t be bigger than fee');
			setNetAmount(0);
		} else if (Number(value) === fee) {
			setNetAmountError('Gross amount can`t be equal fee');
			setNetAmount(0);
		} else {
			const calc = Number(value) - fee;
			setNetAmount(Number(calc.toFixed(5)));
			setNetAmountError('');
		}
	};
	const changeFee = (value: string) => {
		setFee(Number(value));
		if (Number(value) > indicatedAmount) {
			setNetAmountError('Fee can`t be bigger than gross amount');
			setNetAmount(0);
		} else if (Number(value) === indicatedAmount) {
			setNetAmountError('Fee can`t be equal gross amount');
			setNetAmount(0);
		} else {
			const calc = indicatedAmount - Number(value);
			setNetAmount(Number(calc.toFixed(5)));
			setNetAmountError('');
		}
	};
	const initialValues: IAddCryptoDepositForm = {
		date: '',
		asset_code: '',
		network: '',
		indicated_amount: '',
		fee: '',
		status: '',
		tx_hash: '',
		note: '',
		twoFa: '',
	};

	const validationSchema = yup.object().shape({
		date: yup.string().required('Please, enter date!'),
		asset_code: yup.string().required('Please, choose currency!'),
		indicated_amount: yup.number().required('Please, enter indicated amount!'),
		fee: yup.number().required('Please, enter fee!'),
		status: yup.string().required('Please, enter status!'),
		tx_hash: yup.string().required('Please, enter tx hash!'),
		note: yup.string().required('Please, enter note!'),
		twoFa: yup
			.string()
			.required('Please, enter 2FA code!')
			.max(6, 'Please enter 6 character 2FA code.'),
	});
	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const statuses = [
		{
			id: 1,
			name: 'Completed',
			value: EApiCryptoTransactionStatus.COMPLETED,
		},
		{
			id: 4,
			name: 'Pending',
			value: EApiCryptoTransactionStatus.PENDING,
		},
		{
			id: 6,
			name: 'Rejected',
			value: EApiCryptoTransactionStatus.REJECTED,
		},
	];
	const statusFilterOptions = statuses.map(({ name }) => name);

	useLayoutEffect(() => {
		if (userId) {
			dispatch(changeBankAccountsUserId(Number(userId)));
		}
		setFee(0);
		setNetAmount(0);
		setIndicatedAmount(0);
		setWalletAddress('');
		setSelectedNetwork(null);
		setSelectedCurrency(null);
		dispatch(clearUserWalletAddresses());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	useEffect(() => {
		setWalletAddress(userWalletAddressData.address);
	}, [userWalletAddressData]);

	const handleGetAssetIdByCode = (currentCode: string) => {
		return cryptoCurrencyData.filter(({ code }) => code === currentCode.toLowerCase())[0].id;
	};

	const handleCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedCurrency(value);
		setSelectedNetwork(null);
		dispatch(clearUserWalletAddresses());
	};

	const handleCloseModal = () => {
		setReset(true);
		setFee(0);
		setNetAmount(0);
		setIndicatedAmount(0);
		setWalletAddress('');
		setSelectedNetwork(null);
		setSelectedCurrency(null);
		setUserId('');
		dispatch(clearUserWalletAddresses());
		closeModal();
	};

	const handleCloseModalClick = () => {
		closeModal();
		setNetAmountError('');
	};
	// console.log(selectedCurrency?.chains);
	const handleNetworkChange = (value: any) => {
		setSelectedNetwork(value);
		dispatch(
			getUserWalletAddressesRequest({
				userId: Number(userId),
				asset_id: selectedCurrency?.id || 1,
				chain_id: value.id,
			}),
		);
	};

	return (
		<>
			<Popup open={open} nested onClose={handleCloseModal} closeOnDocumentClick={false}>
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={handleCloseModal} />
					<div className="popup-header popup-header--mb-20">
						<p className="popup-header__title">Add New Transaction</p>
					</div>
					<Formik
						validateOnBlur
						enableReinitialize
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: IAddCryptoDepositForm, { resetForm, setSubmitting }) => {
							if (userId) {
								const params: IAddDepositCryptoWorker = {
									apiParams: {
										user_id: Number(userId),
										asset_id: handleGetAssetIdByCode(values.asset_code),
										chain_id: selectedNetwork.id,
										status: values.status.toLowerCase(),
										tx_hash: values.tx_hash,
										amount: Number(values.indicated_amount),
										net_fee: Number(netAmount),
										fee: Number(values.fee),
										gross_fee: Number(values.indicated_amount),
										admin_notes: values.note,
										date: values.date,
										totp: values.twoFa,
									},
									onFinally: (hasError: boolean) => {
										setSubmitting(false);
										dispatch(clearError());
										if (!hasError) {
											resetForm();
											handleCloseModal();
										}
									},
								};
								dispatch(addDepositCryptoRequest(params));
							}
						}}
					>
						{({ isSubmitting, isValid, dirty, values }) => (
							<Form>
								<div className="popup-body popup-body--transaction">
									<div className="phone-number-input-wrap">
										<UserSearch
											handleChangeUserId={handleChangeUserId}
											resetForm={reset}
											handleResetForm={() => setReset(false)}
										/>
										<div className="mt-16">
											<Field name="date" component={SelectDate} />
										</div>

										<div className="input-line">
											<Field
												type="text"
												searchField
												name="asset_code"
												title="Currency"
												component={CurrencySelect}
												arr={cryptoCurrencyData}
												onChange={handleCurrencyChange}
												className="select--add-fiat-transaction thin--font"
												disabled={!userId}
												activeValue={selectedCurrency}
											/>

											<Field
												required
												type="text"
												name="network"
												title="Network"
												component={NetworkSelect}
												arr={selectedCurrency?.chains}
												placeholder="Network"
												disabled={!selectedCurrency?.chains}
												onChange={handleNetworkChange}
												activeValue={selectedNetwork}
												resetCustomSelect={resetNetwork}
												setResetCustomSelect={setResetNetwork}
											/>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													onChange={changeIndicatedAmount}
													name="indicated_amount"
													title="Gross amount"
												/>
											</div>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													onChange={changeFee}
													name="fee"
													title="Fee"
												/>
											</div>
											<div className="number__input">
												<div
													className={cn('input input-item--auto-calc', {
														'input--error': netAmountError,
													})}
												>
													<label>
														<p className="input__name">Net Amount</p>
														<div className="input-wrapper">
															<input
																className="input-item"
																placeholder="0.00"
																readOnly
																value={netAmount || ''}
																type="number"
															/>
															<span className="input-icon" />
														</div>
													</label>
													{!!netAmountError && (
														<div className="input-notify">
															<span className="input-notify__text">* {netAmountError}</span>
														</div>
													)}
												</div>
											</div>
											<Field
												required
												type="text"
												name="status"
												title="Status"
												component={Select}
												arr={statusFilterOptions}
												placeholder="Status"
											/>
										</div>
										<Field
											required
											type="text"
											placeholder="Tx Hash"
											component={Input}
											name="tx_hash"
											title="Tx Hash"
										/>
										<div className="input">
											<label htmlFor="Wallet Address">
												<p className="input__name">Wallet Address</p>
												<div className="input-wrapper">
													<input
														className="input-item"
														name="wallet_address"
														type="text"
														readOnly
														placeholder=""
														value={walletAddress}
													/>
													<span className="input-icon" />
												</div>
											</label>
										</div>
										<Field
											required
											type="textarea"
											placeholder="Text"
											component={Input}
											name="note"
											title="Note"
										/>
										<Field
											required
											type="text"
											placeholder="Enter 2fa code"
											component={InputTotp}
											name="twoFa"
											title="2FA Code"
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--column">
										<button
											type="submit"
											className="btn btn-primary btn--full"
											disabled={!(isValid && dirty) || isSubmitting || !userId}
										>
											Confirm
										</button>
										<button
											type="button"
											onClick={handleCloseModal}
											className="btn btn-outline btn-secondary"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				<CreateDepositAccountPopUp
					open={createDepositOpen}
					closeModal={handleCloseCreateDeposit}
					currencySelected={selectedCurrency}
				/>
			</Popup>
		</>
	);
};

export default AddCryptoDepositTransaction;
