import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IDashboardStore } from './types';

// ==========================================:
const getDashboardState = (state: IStoreState): IDashboardStore => state.dashboard;

export const getDashboard = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard,
);

// ====================================================:
export const getB2C2Balances = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.b2c2Balance,
);

export const depositBalances = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.depositBalance,
);

export const getLiquidity = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.liquidity,
);

export const getPendingFees = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.pendingFee,
);

export const platformBalances = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.platformBalance,
);

export const poolBalances = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.poolBalance,
);

export const profitBalances = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.profitBalance,
);

export const withdrawBalances = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.withdrawBalance,
);

export const getDashboardLayers = createSelector(
	[getDashboardState],
	(dashboard: IDashboardStore) => dashboard.layers,
);
