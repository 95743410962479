import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
	createCryptoDetailsRequest,
	createCryptoDetailsSuccess,
	createFiatDetailsRequest,
	createFiatDetailsSuccess,
	createTradeDetailsRequest,
	createTradeDetailsSuccess,
	deleteCryptoDetailsFilesRequest,
	deleteCryptoDetailsFilesSuccess,
	deleteFiatDetailsFilesRequest,
	deleteFiatDetailsFilesSuccess,
	deleteTradeDetailsFilesRequest,
	deleteTradeDetailsFilesSuccess,
	getCryptoDetailsRequest,
	getCryptoDetailsSuccess,
	getFiatDetailsRequest,
	getFiatDetailsSuccess,
	getTradeDetailsRequest,
	getTradeDetailsSuccess,
	hideLoading,
	downloadCryptoFileRequest,
	downloadFiatFileRequest,
	downloadTradeFileRequest,
} from './reducer';
import { api } from '../../../services';
import { ICreateTradeDetailsRequest } from '../../../services/api/detailedView/types';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { IFileDownloadPayload } from './types';

function* getTradeDetailsWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.detailedView.getTradeDetails, payload);
		yield put(getTradeDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* createTradeDetailsWorker({ payload }: PayloadAction<ICreateTradeDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postTradeDetails, payload.body);
		yield put(createTradeDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* deleteTradeDetailsFilesWorker({ payload }: PayloadAction<ICreateTradeDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.deleteFilesTradeDetails, payload);
		yield put(deleteTradeDetailsFilesSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* getFiatDetailsWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.detailedView.getFiatDetails, payload);
		yield put(getFiatDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* createFiatDetailsWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postFiatDetails, payload.body);
		yield put(createFiatDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* deleteFiatDetailsFilesWorker({ payload }: PayloadAction<ICreateTradeDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.deleteFilesFiatDetails, payload);
		yield put(deleteFiatDetailsFilesSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* getCryptoDetailsWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.detailedView.getCryptoDetails, payload);
		yield put(getCryptoDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* createCryptoDetailsWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postCryptoDetails, payload.body);
		yield put(createCryptoDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* deleteCryptoDetailsFilesWorker({ payload }: PayloadAction<ICreateTradeDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.deleteFilesCryptoDetails, payload);
		yield put(deleteCryptoDetailsFilesSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		console.log(error);
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* downloadCryptoFileWorker({ payload }: PayloadAction<IFileDownloadPayload>) {
	try {
		yield call(api.detailedView.downloadCryptoFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/admin/cloud/trs/crypto/download/${
				payload.file
			}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

function* downloadFiatFileWorker({ payload }: PayloadAction<IFileDownloadPayload>) {
	try {
		yield call(api.detailedView.downloadFiatFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/admin/cloud/trs/fiat/download/${
				payload.file
			}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}
function* downloadTradeFileWorker({ payload }: PayloadAction<IFileDownloadPayload>) {
	try {
		yield call(api.detailedView.downloadTradeFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/admin/cloud/trs/trade/download/${
				payload.file
			}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	}
}

export function* detailedViewSaga() {
	yield takeEvery(getTradeDetailsRequest, getTradeDetailsWorker);
	yield takeEvery(getFiatDetailsRequest, getFiatDetailsWorker);
	yield takeEvery(getCryptoDetailsRequest, getCryptoDetailsWorker);
	yield takeEvery(createTradeDetailsRequest, createTradeDetailsWorker);
	yield takeEvery(createFiatDetailsRequest, createFiatDetailsWorker);
	yield takeEvery(createCryptoDetailsRequest, createCryptoDetailsWorker);
	yield takeEvery(deleteTradeDetailsFilesRequest, deleteTradeDetailsFilesWorker);
	yield takeEvery(deleteCryptoDetailsFilesRequest, deleteCryptoDetailsFilesWorker);
	yield takeEvery(deleteFiatDetailsFilesRequest, deleteFiatDetailsFilesWorker);
	yield takeEvery(downloadCryptoFileRequest, downloadCryptoFileWorker);
	yield takeEvery(downloadFiatFileRequest, downloadFiatFileWorker);
	yield takeEvery(downloadTradeFileRequest, downloadTradeFileWorker);
}
