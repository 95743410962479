import React, { useEffect, useLayoutEffect, useMemo } from 'react';

import WalletsTable from 'components/tables/WalletsTable';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserLayers,
	getUserReferralLoader,
	getUserWallets,
	getUserWalletsCrypto,
	getUserWalletsFiat,
} from 'redux/reducers/users/selectors';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import {
	getUserWalletsRequest,
	updateBalancesRequest,
	updateUserLayers,
} from 'redux/reducers/users/reducer';
import useWebSocket from 'react-use-websocket';
import { useParams } from 'react-router';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import { ECoinType } from 'components/tables/WalletsTable/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { IUpdateBalancesPayload } from 'redux/reducers/users/types';
import { getWalletsLayers } from 'redux/reducers/walletBalance/selectors';

import { ILayersData, layersFilter } from 'utils/layersFilter';
import IconSvg from '../../../../ui/Svg/IconSvg';
import { currencyDataRequest } from '../../../../redux/reducers/currency/reducer';
import Loading from '../../../../layouts-elements/Loading/Loading';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const BalancesTabView = () => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const wallet = useSelector(getUserWallets);
	const walletsCryptoList = useSelector(getUserWalletsCrypto);
	const loading = useSelector(getUserReferralLoader);
	const walletsFiatList = useSelector(getUserWalletsFiat);
	const permissions = useSelector(getPermissions);

	const layers = useSelector(getUserLayers);

	const layersData: ILayersData | Record<string, any> = layersFilter('btc_eur', layers);

	const { lastMessage } = useWebSocket(process.env.REACT_APP_SOCKET_URL as string);

	useEffect(() => {
		if (lastMessage?.data) {
			dispatch(updateUserLayers(JSON.parse(lastMessage?.data)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastMessage]);

	useLayoutEffect(() => {
		dispatch(getUserWalletsRequest({ user_id: userId }));
	}, [dispatch, userId]);
	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	const handleBalancesUpdate = () => {
		const params: IUpdateBalancesPayload = {
			apiParams: {
				user_id: Number(userId),
			},
			onFinally: (response) => {
				if (response === 'updated') {
					dispatch(getUserWalletsRequest({ user_id: userId }));
				}
			},
		};
		dispatch(updateBalancesRequest(params));
	};

	const fiatBalance = useMemo(() => {
		return walletsFiatList?.reduce((acc: number, item: any) => {
			const layerData: ILayersData | Record<string, any> = layersFilter(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`${item.asset.code}_eur`,
				layers,
			);
			if (layerData?.data && item.balance) {
				return acc + Number(item?.balance) / Number(layerData?.data?.sell[1]);
			}
			return acc + Number(item?.balance_eur);
		}, 0);
	}, [layers, walletsFiatList]);

	const cryptoBalance = useMemo(() => {
		return walletsCryptoList?.reduce((acc: number, item: any) => {
			const layerData: ILayersData | Record<string, any> = layersFilter(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`${item.asset.code}_eur`,
				layers,
			);
			if (layerData?.data && item.balance) {
				return acc + Number(item?.balance) * Number(layerData?.data?.sell[1]);
			}
			return acc + Number(item?.balance_eur);
		}, 0);
	}, [layers, walletsCryptoList]);

	const totalBalanceCrypto = useMemo(() => {
		if (layersData?.data && cryptoBalance && fiatBalance)
			return (Number(cryptoBalance) + Number(fiatBalance)) / Number(layersData?.data?.sell[1]);
		return 0;
	}, [fiatBalance, cryptoBalance, layersData?.data]);

	return (
		<div className="user-management user-management-balance">
			<div className="user-management-body">
				<div className="wallet-content-balance-wrap">
					<div className="wallet-content-balance">
						<div className="wallet-content-balance-item">
							<div className="wallet-content-balance-item__title">
								<p>Estimates Balance</p>
							</div>
							<div className="wallet-content-balance-item__value">
								<p>
									{loading ? (
										<Loading height={29} />
									) : (
										<>
											{toFixedNumber(
												Number(totalBalanceCrypto) * Number(layersData?.data?.sell[1]),
												'',
												true,
											)}{' '}
											<span> EUR</span>
										</>
									)}
								</p>
							</div>
							<div className="wallet-content-balance-item__user-currensy">
								<p>
									{loading ? (
										<Loading height={29} fontSize={16} />
									) : (
										<>
											≈ {toFixedNumber(totalBalanceCrypto, 'BTC', true)} <span>BTC</span>
										</>
									)}
								</p>
							</div>
						</div>

						<div className="wallet-content-balance-item">
							<div className="wallet-content-balance-item__title">
								<p>Fiat Balance</p>
							</div>
							<div className="wallet-content-balance-item__value">
								<p>
									{loading ? (
										<Loading height={29} />
									) : (
										<>
											{toFixedNumber(Number(fiatBalance), '', true)}
											<span>EUR</span>
										</>
									)}
								</p>
							</div>

							<div className="wallet-content-balance-item__user-currensy">
								<p>
									{loading ? (
										<Loading height={29} fontSize={16} />
									) : (
										<>
											≈{' '}
											{toFixedNumber(
												Number(fiatBalance) / Number(layersData?.data?.sell[1]),
												'BTC',
												true,
											)}
											<span>BTC</span>
										</>
									)}
								</p>
							</div>
						</div>

						<div className="wallet-content-balance-item">
							<div className="wallet-content-balance-item__title">
								<p>Crypto Balance</p>
							</div>
							<div className="wallet-content-balance-item__value">
								<p>
									{loading ? (
										<Loading height={29} />
									) : (
										<>
											{toFixedNumber(Number(cryptoBalance), '', true)}
											<span>EUR</span>
										</>
									)}
								</p>
							</div>
							<div className="wallet-content-balance-item__user-currensy">
								<p>
									{loading ? (
										<Loading height={29} fontSize={16} />
									) : (
										<>
											≈{' '}
											{toFixedNumber(
												Number(cryptoBalance) / Number(layersData?.data?.sell[1]),
												'BTC',
												true,
											)}
											<span>BTC</span>
										</>
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="wallet-content-tables">
					{!loading && (
						<WalletsTable
							permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
							walletsList={walletsFiatList}
							title="Fiat Balances"
							perPage={5}
							type={ECoinType.FIAT}
						/>
					)}

					{loading ? <Loading /> : null}
					<div className="block--middle mb-8">
						<div className="table-title table-title__wrap">
							<div className="table-title table-title_crypto">
								<p>Crypto Balances</p>
							</div>
							{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && (
								<button
									type="button"
									className="btn btn-primary btn-primary--sm"
									onClick={handleBalancesUpdate}
									disabled={loading}
								>
									Update Balances
								</button>
							)}
						</div>
						{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && (
							<button
								onClick={() => {
									const data = {
										title: `Send to Pool Wallet`,
										payload: {
											type: 'All user balances transfer wallet',
											asset_id: 10,
											userId,
										},
									};
									dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
									dispatch(setPopUpData(data));
								}}
								type="button"
								className="button--send-pool-wallet"
							>
								Send All to Pool Wallet
								<div className="button--send-pool-wallet_arrow">
									<IconSvg name="keyboard-arrow-right" w="7" h="13" />
								</div>
							</button>
						)}
					</div>
					{!loading && (
						<WalletsTable
							permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
							walletsList={walletsCryptoList}
							title=""
							perPage={5}
							type={ECoinType.CRYPTO}
						/>
					)}

					{loading ? <Loading /> : null}
				</div>
			</div>
		</div>
	);
};

export default BalancesTabView;
