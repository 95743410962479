import { useFormatDateTime } from 'hooks/useFormatDateTime';
import React, { FC } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { capitalize, shortNotation } from 'services/utils/strings';
import { useDispatch, useSelector } from 'react-redux';
import { getCryptoHistory } from 'redux/reducers/transactions/selectors';
import { addDetailedQuery } from 'redux/reducers/transactionsFilters/reducer';
import { roundingNumber } from 'services/utils/roundingNumber';
import IconSvg from 'ui/Svg/IconSvg';
import { statusTitle } from '../../utils';
import { ICryptoHistoryRowProps } from '../types';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const CryptoHistoryRow: FC<ICryptoHistoryRowProps> = ({ item }) => {
	const [date, time] = useFormatDateTime(item.created_at);
	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const currentPage = useSelector(getCryptoHistory)?.current_page;
	const perPage = useSelector(getCryptoHistory)?.per_page;

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	const handleDetailedViewRedirection = () => {
		dispatch(addDetailedQuery({ currentPage, perPage }));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div>
					<p className="td-name--flex">{date}</p>
					<span className="td-more-info">{time}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p>{capitalize(item.type)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.asset.code.toUpperCase()}</p>
				<span className="td-more-info">{item.asset.name}</span>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Gross</p>
				<div>
					<p>{toFixedNumber(item?.gross_fee, item.asset.code, true)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<div>
					<p>{toFixedNumber(item.fee, item.asset.code, true)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Net</p>
				<p>{toFixedNumber(item?.net_fee, item.asset.code, true)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Destination</p>
				<div className="copy-button copy-button--long-type-2">
					{item?.destination && (
						<>
							<span className="copy-button__text">{shortNotation(item?.destination, 8, 3)}</span>
							<CopyToClipboard text={item?.destination} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="16" h="16" />
									<span>Copy</span>
								</button>
							</CopyToClipboard>
						</>
					)}
					{!item?.destination && item?.address?.address && (
						<>
							<span className="copy-button__text">
								{shortNotation(item?.address?.address, 8, 3)}
							</span>
							<CopyToClipboard text={item?.address?.address} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="16" h="16" />
									<span>Copy</span>
								</button>
							</CopyToClipboard>
						</>
					)}
					{!item?.destination && !item?.address?.address && (
						<span className="copy-button__text">-</span>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx Hash</p>
				<div className="copy-button copy-button--long-type-2">
					{item.ext_tx_hash ? (
						<>
							<span className="copy-button__text">{shortNotation(item.ext_tx_hash, 8, 3)}</span>
							<CopyToClipboard text={item.ext_tx_hash} onCopy={handleCopy}>
								<button type="button">
									<IconSvg name="copy" w="16" h="16" />
									<span>Copy</span>
								</button>
							</CopyToClipboard>
						</>
					) : (
						<span className="copy-button__text">-</span>
					)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx ID</p>
				<Link
					to={{
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						pathname: `${url}/${item.id}`,
						state: {
							txId: item.id,
							fromTransfersCryptoUser: true,
							userId: item.user_id,
						},
					}}
					onClick={handleDetailedViewRedirection}
				>
					{item.id}
				</Link>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Status</p>
				<span className={`${item.status}-status`}>{statusTitle[item.status]}</span>
			</div>
		</div>
	);
};

export default CryptoHistoryRow;
