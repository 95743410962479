import { IChangeStatusPayload } from 'redux/reducers/admins/types';
import { EApiTransactionType } from 'services/api/transactions/types';
import { ICheckFireblockIdRequest } from '../redux/reducers/users/types';

export const endpoint = {
	auth: {
		LOGIN: '/admin/login',
		REFRESH_TOKEN: '/token/refresh',
		REGISTRATION: '/register',
		LOGOUT: '/admin/logout',
		RESET_PASSWORD: '/admin/password_reset',
		NEW_PASSWORD: '/user/password',
		NEW_PASSWORD_ADMIN: '/admin/password',
		RESET_EMAIL_CONFIRM: '/reset-token',
		EMAIL_CONFIRM: '/email_confirmation',
		IP_CONFIRM: '/whitelist_ip',
		GENERATE_SMS: '/generate_sms',
		RESET_TWOFA: '/admin/2fa/reset',
		POST_GENERATE_SECRET_CODE_FOR_FILE_DOWNLOAD: '/admin/generate_secret_for_download_file',
		GET_PERMISSIONS: (id: number) => `/admin/admins/${id}`,
		GENERATE_CODE_FOR_TWOFA: '/admin/2fa/generate_secret_code',
		LOGIN_FIRST: '/admin/login/first',
		TWOFA_ENABLE: '/admin/2fa/enable/by_token',
	},
	dashboard: {
		// DEPOSIT_BALANCE: '/admin/dashboard/deposit_balance/crypto',
		DEPOSIT_BALANCE: '/admin/dashboard/deposit_balance_main_page/crypto',
		// DEPOSIT_BALANCE: '/admin/dashboard/deposit_balances_main_page/crypto',
		PROFIT_BALANCE: '/admin/dashboard/profit_balance/crypto',
		POOL_BALANCE: '/admin/dashboard/pool_balance/crypto',
		WITHDRAW_BALANCE: '/admin/dashboard/withdraw_balance/crypto',
		LIQUIDITY: (type: 'crypto' | 'fiat') => `/admin/dashboard/liquidity_main_page/${type}`,
		LIQUIDITY_DASHBOARD: (currency: string) => `/admin/dashboard/liquidity_main_page/${currency}`,
		B2C2: (type: 'crypto' | 'fiat') => `/admin/dashboard/b2c2_balance/${type}`,
		PENDING_FEE: (type: 'crypto' | 'fiat') => `/admin/dashboard/pending_fee_main_page/${type}`,
		PLATFORM_BALANCE: (type: 'crypto' | 'fiat') =>
			`/admin/dashboard/platform_balance_main_page/${type}`,
	},
	settings: {
		VERIFICATION: '/settings/verification/upload',
		GENERATE_SECRET_SMS: '/settings/2fa/generate_secret_key',
		TWO_FA_ENABLE: '/settings/2fa/enable',
	},
	confirmation: {
		CONFIRMATION: '/confirmation',
		CONFIRMATION_ENABLE_CHANGE_TWO_FA: '/confirmation/2fa/enable',
		CONFIRMATION_PASSWORD: '/confirmation/password',
		CONFIRMATION_TWO_FA: '/confirmation/2fa/generate',
	},
	transactions: {
		// new
		ALL_BANK_ACCOUNT: `/admin/transactions/fiats/all-bank-accounts`,
		//
		GET_CRYPTO_HISTORY: (userId: number | string) => `/admin/user/${userId}/transactions/cryptos`,
		GET_FIAT_HISTORY: (userId: number | string) => `/admin/user/${userId}/transactions/fiats`,
		GET_TRADE_HISTORY: (userId: number | string) => `/admin/user/${userId}/transactions/trades`,
		GET_CRYPTO_TRANSFERS: '/admin/transactions/crypto/transfers',
		GET_FIAT_TRANSFERS: '/admin/transactions/fiats/transfers',
		GET_CRYPTO_DEPOSITS: '/admin/transactions/crypto/deposits',
		GET_FIAT_DEPOSITS: '/admin/transactions/fiats/deposits',
		POST_FIAT_DEPOSIT: '/admin/transactions/fiats/deposit',
		GET_CRYPTO_TRANSACTIONS: '/admin/transactions/crypto',
		GET_FIAT_TRANSACTIONS: '/admin/transactions/fiats',
		PUT_FIAT_TRANSACTIONS_WITHDRAWALS: (id: number) =>
			`/admin/transactions/fiats/withdrawals/${id}`,
		PUT_FIAT_TRANSACTIONS_DEPOSITS: (id: number) => `/admin/transactions/fiats/deposits/${id}`,
		PUT_CRYPTO_WITHDRAWAL_APPROVE: (id: number) =>
			`/admin/transactions/crypto/withdrawals/${id}/approve`,
		PUT_CRYPTO_WITHDRAWAL_REJECT: (id: number) =>
			`/admin/transactions/crypto/withdrawals/${id}/reject`,
		PUT_CRYPTO_NOTE: `admin/transactions/note/crypto`,
		GET_POOLED_DEPOSITS: '/admin/transactions/crypto/pooled',
		GET_POOLED_DEPOSITS_FILE: (fileType: 'csv' | 'xlsx', token: string, type: string) =>
			`/admin/transactions/crypto_pooled/report/${fileType}/${token}${type ? `/${type}` : ''}`,
		GET_WITHDRAW_LIMITS_CRYPTO: '/admin/transactions/withdraw-limits/crypto',
		GET_WITHDRAW_LIMITS_FIAT: '/admin/transactions/withdraw-limits/fiat',
		PUT_WITHDRAW_LIMIT: '/admin/transactions/withdraw-limit',
		GET_WITHDRAW_LIMIT_TOTAL: '/admin/transactions/withdraw-limit-total',
		PUT_WITHDRAW_LIMIT_TOTAL: '/admin/transactions/withdraw-limit-total',
		GET_TRADES: '/admin/transactions/trade',
		GET_TRADES_PERMISSION: '/admin/dashboard/transactions/trade',
		GET_TRADES_FILE: (fileType: 'csv' | 'xlsx', token: string, adminId: string) =>
			`/admin/trade/report/trades/${fileType}/${token}/${adminId}`,
		GET_TRADES_LIMITS_CRYPTO: '/admin/transactions/trading-limits/crypto',
		GET_TRADES_LIMITS_FIAT: '/admin/transactions/trading-limits/fiat',
		PUT_TRADES_LIMITS: '/admin/transactions/trading-limit',
		GET_CRYPTO_TRANSACTION_FILE: (
			token: string,
			fileType: 'csv' | 'xlsx',
			type?: EApiTransactionType,
			adminId?: string,
		) =>
			`/admin/transactions/crypto/report/${fileType}/${token}${adminId ? `/${adminId}` : ''}${
				type ? `/${type}` : ''
			}`,
		GET_FIAT_TRANSACTION_FILE: (
			token: string,
			fileType: 'csv' | 'xlsx',
			type?: EApiTransactionType,
			adminId?: string,
		) =>
			`/admin/transactions/fiats/report/${fileType}/${token}${adminId ? `/${adminId}` : ''}${
				type ? `/${type}` : ''
			}`,
		GET_PROFIT_WALLETS: '/admin/transactions/profit-transfers',
		GET_PROFIT_WALLETS_FILE: (fileType: 'csv' | 'xlsx', token: string, adminId: string) =>
			`/admin/transactions/crypto_profit/report/${fileType}/${token}/${adminId}`,

		GET_PENDING_DEPOSITS: (type: 'fiat' | 'crypto') =>
			`/admin/transactions/pending_deposit/${type}`,
		GET_PENDING_DEPOSITS_FILE: (
			fileType: 'csv' | 'xlsx',
			token: string,
			adminId: string,
			assetType: 'fiat' | 'crypto',
		) => `/admin/transactions/pending_deposit/report/${fileType}/${token}/${adminId}/${assetType}`,
		PUT_PENDING_DEPOSIT_STATUS: (id: number) => `/admin/transactions/fiats/deposits/${id}`,
		PUT_PENDING_DEPOSIT_CRYPTO_APPROVE: (id: number) =>
			`/admin/transactions/crypto/deposits/${id}/approve`,
		PUT_PENDING_DEPOSIT_CRYPTO_REJECT: (id: number) =>
			`/admin/transactions/crypto/deposits/${id}/reject`,
		PUT_PENDING_DEPOSIT_APPROVE: (id: number) =>
			`/admin/transactions/pending_deposit/approve/${id}`,
		PUT_PENDING_DEPOSIT_REJECT: (id: number) => `/admin/transactions/pending_deposit/reject/${id}`,
		PUT_PENDING_DEPOSIT_NOTE: (id: number) => `admin/transactions/pending_deposit/note/${id}`,

		GET_PENDING_WITHDRAWALS: (type: 'fiat' | 'crypto') =>
			`/admin/transactions/pending_withdrawal/${type}`,
		GET_PENDING_WITHDRAWALS_FILE: (
			fileType: 'csv' | 'xlsx',
			token: string,
			adminId: string,
			assetType: 'fiat' | 'crypto',
		) =>
			`/admin/transactions/pending_withdrawal/report/${fileType}/${token}/${adminId}/${assetType}`,
		PUT_PENDING_WITHDRAWAL_STATUS: (id: number) => `/admin/transactions/fiats/withdrawals/${id}`,
		PUT_PENDING_WITHDRAWAL_CRYPTO_APPROVE: (id: number) =>
			`/admin/transactions/crypto/withdrawals/${id}/approve`,
		PUT_PENDING_WITHDRAWAL_CRYPTO_REJECT: (id: number) =>
			`/admin/transactions/crypto/withdrawals/${id}/reject`,
		PUT_PENDING_WITHDRAWAL_APPROVE: (id: number) =>
			`/admin/transactions/pending_withdrawal/approve/${id}`,
		PUT_PENDING_WITHDRAWAL_REJECT: (id: number) =>
			`/admin/transactions/pending_withdrawal/reject/${id}`,
		PUT_PENDING_WITHDRAWAL_CANCEL: (id: number) =>
			`/admin/transactions/pending_withdrawal/cancel/${id}`,
		PUT_PENDING_WITHDRAWAL_NOTE: (id: number) => `admin/transactions/pending_withdrawal/note/${id}`,
		POST_ADD_DEPOSIT_CRYPTO: '/admin/transactions/crypto/deposit',
		POST_ADD_WITHDRAWAL_FIAT: '/admin/transactions/fiats/withdraw',
		POST_ADD_WITHDRAWAL_CRYPTO: '/admin/transactions/crypto/withdraw',
		POST_ADD_TRADE: '/admin/transactions/trade',
		GET_LEDGER_TRANSACTIONS: '/admin/transactions/ledger',
	},
	users: {
		GET_ONE_USER: (userId: number | string) => `/admin/user/${userId}`,
		ALL_USERS: '/admin/users',
		ALL_USERS_PERMISSION: '/admin/transactions/fiats/users',
		RESET_REQUESTS: '/admin/user-2fa/resets/user',
		USER_PASSWORD_RESET_REQUESTS: '/admin/user/password/resets/user',
		TWO_FA_RESET: '/admin/user-2fa/resets/approve',
		TWO_FA_REJECT: '/admin/user-2fa/resets/reject',
		USER_PASSWORD_RESET: '/admin/user/password/resets/approve',
		USER_PASSWORD_REJECT: '/admin/user/password/resets/reject',
		ADMIN_REQUIRED_EMAILS: '/admin/email',
		ADMIN_RESET_REQUESTS: '/admin/admin/password/resets/admin',
		ADMIN_PASSWORD_RESET: '/admin/admin/password/resets/approve',
		ADMIN_PASSWORD_REJECT: '/admin/admin/password/resets/reject',
		GET_ADMIN_TWO_FA_REQUESTS: '/admin/admin-2fa/resets/admin',
		APPROVE_ADMIN_TWO_FA_REQUEST: '/admin/admin-2fa/resets/approve',
		REJECT_ADMIN_TWO_FA_REQUEST: '/admin/admin-2fa/resets/reject',
		GET_USERS_FILE: (token: string, fileType: 'csv' | 'xlsx') =>
			`/admin/users/report/${fileType}/${token}`,
		SET_USER_STATUS: (id: number) => `/admin/user/${String(id)}/account/status`,
		USER_FIREBLOCK_ID: (id: number) => `/admin/user/${String(id)}/fireblocks_vault`,
		CHECK_VALID_FIREBLOCKS: ({ fireblockId, userId }: { userId?: number; fireblockId: string }) =>
			`/admin/user${userId ? `/${userId}` : ''}/check/fireblocks/${fireblockId}`,
		DOWNLOAD_FILE: (file: string) => `/admin/cloud/doc/download/${file}`,
	},
	admins: {
		ADMINS: (id: number | null) => `/admin/admins${id ? `/${id}` : ''}`,
		CHANGE_ADMIN_STATUS: ({ id, status }: IChangeStatusPayload) =>
			`/admin/admins/change_status/${String(id)}/${Number(status)}`,
		PERMISSIONS: '/admin/permissions',
	},
	user: {
		USER_BLOCK: (id: number, event: string) => `/admin/user/${id}/${event}`,
		USER_WALLETS: (userId: number | string) => `/admin/user/${userId}/wallets`,
		USER_REFERRAL: (userId: number | string) => `/admin/referral/user/${userId}/referrals`,
		DEL_USER_REFERRAL: (userId: number | string) => `/admin/user/${userId}/referral`,
		REFERRAL_SYSTEM: (userId: number | string) => `/admin/referral/${userId}/commission`,
		USER_LIQUIDITY_PROVIDER: (userId: string) => `/admin/user/${userId}/liquidity-provider`,
		USER_ARCHIVED: (id: number, event: string) => `/admin/user/${id}/${event}`,
		CREATE_USER: `/admin/user`,
		USER_EMAILS: (id: string) => `/admin/user/${id}/email/user`,
		ADMIN_EMAILS: (id: string) => `/admin/user/${id}/email/admin`,
		USER_ADMIN_EMAILS: (id: string) => `/admin/user/${id}/email`,
		USER_FEE: (
			userId: number | string,
			type: string | undefined,
			currentPage: number,
			perPage: number | undefined,
		) =>
			`/admin/user/${userId}/fees?current_page=${currentPage}&per_page=${perPage || '1000000'}${
				type ? '&type=' : ''
			}${type || ''}`,
		PUT_USER_FEE: (userId: number | string) => `/admin/user/${userId}/fees`,
		USER_FEE_SPREAD: (userId: number | string, currentPage: number, perPage: number | undefined) =>
			`/admin/user/${userId}/spread?current_page=${currentPage}&per_page=${perPage || '1000000'}`,
		PUT_USER_FEE_SPREAD: (userId: number | string) => `/admin/user/${userId}/spread`,
		PUT_USER_REFERRAL: 'admin/referral/user/commission',
		TRANSFER_TO_POOL: '/admin/user/2/transfer-to-pool',
		ALL_TRANSFER_TO_POOL: (userId: number | string) =>
			`/admin/user/${userId}/all_assets/transfer-to-pool`,
		GENERATE_ZIP_SECRET: (userId: number) => `/admin/user/${userId}/zip/secret`,
		GET_SATCHEL_SETTINGS: (userId: number | string) => `/admin/user/${userId}/satchel-settings`,
		PUT_SATCHEL_SETTINGS: (userId: string | number) =>
			`/admin/user/${String(userId)}/satchel-settings`,
	},
	walletAddresses: {
		WALLET_ADDRESSES: (userId: number) => `/admin/user/${userId}/wallet-addresses`,
		WALLET_ADDRESS: (userId: number) => `/admin/user/${userId}/wallet-address`,
		USER_WALLET_ADDRESSES: (userId: number, asset_id: number, chain_id: number) =>
			`/admin/address/user/?user_id=${userId}&chain_id=${chain_id}&asset_id=${asset_id}`,
	},
	bankAccounts: {
		BANK_ACCOUNTS: (userId: number) => `/admin/user/${userId}/bank-accounts`,
		BANK_ACCOUNT: (userId: number) => `/admin/user/${userId}/bank-account`,
	},
	tradingLimits: {
		LIMITS: (userId: string | number) => `/admin/user/${userId}/limits`,
		LIMITS_CRYPTO: (userId: string | number) => `/admin/user/${userId}/limits/crypto`,
		LIMITS_FIAT: (userId: string | number) => `/admin/user/${userId}/limits/fiat`,
		UPDATE_LIMIT: (userId: string | number) => `/admin/user/${userId}/limit`,
	},
	transferLimits: {
		LIMITS: (user_id: string | number, type: string) =>
			`/admin/transaction-limits/?user_id=${user_id}&type=${type}`,
		UPDATE_LIMIT: (id: string | number) => `/admin/transaction-limits/${id}/update`,
	},
	referrals: {
		COMMISSION: 'admin/referral/commission',
		GET_USERS: 'admin/referral/user',
		GET_FREE_USERS: 'admin/referral/free-users',
		GET_REFERRALS_BY_USER: (userId: string | number) => `admin/referral/user/${userId}/referrals`,
		DEL_REFERRAL_BY_USER: 'admin/referral',
		GET_REFERRALS_FILE: (fileType: 'csv' | 'xlsx', token: string, userId?: number | string) =>
			`admin/referral/user/report/${fileType}/${token}/0${userId ? `/${userId}` : ''}`,
		UPDATE_REFERRAL_USER_FEE: 'admin/referral/user/commission',
	},
	fee: {
		GET_FEE: () => `/admin/fee`,
		PUT_FEE: () => `/admin/fee`,
		GET_SPREAD: () => `/admin/fee/asset_pairs`,
		PUT_SPREAD: () => `/admin/fee/asset_pairs`,
		GET_CURRENCLES: () => `/admin/fee/assets`,
		PUT_CURRENCLES: () => `/admin/fee/asset`,
	},
	currency: {
		GET_CURRENCY: '/assets',
		GET_CURRENCY_USER: (userId: string, value: string) => `admin/user/asset/${userId}/${value}`,
		PUT_CURRENCY_USER: `admin/user/asset`,
	},

	walletBalances: {
		GET_LIQUIDITY: (currency: string) => `/admin/dashboard/liquidity/${currency}`,
		GET_LIQUIDITY_REPORT: (fileName: string, key: string, currency: string) =>
			`/admin/dashboard/report/liquidity/${fileName}/${key}/${currency}`,
		GET_PENDING_FEES_REPORT: (fileName: string, key: string, currency: string) =>
			`/admin/dashboard/report/pending_fee/${fileName}/${key}/${currency}`,
		GET_PLATFORM_BALANCES: (currency: string) => `/admin/dashboard/platform_balance/${currency}`,
		GET_DEPOSIT_WALLETS: (currency: string) => `/admin/dashboard/deposit_balance/${currency}`,
		GET_PENDING_FEES: (currency: string) => `/admin/dashboard/pending_fee/${currency}`,
		//
		GET_PLATFORM_BALANCES_USER: (currency: string) =>
			`/admin/dashboard/user_balances/platform_balances/${currency}`,
		GET_DEPOSIT_WALLETS_USER: () => `/admin/dashboard/user_balances/deposit_wallets`,
		// fiat
		GET_DEPOSIT_WALLETS_USER_FIAT: () => `/admin/dashboard/user_balances/fiat`,
		buttons: {
			// postButtons
			// Deposit wallets PAGE
			POST_BUTTONS_DEPOSIT_WALLETS_CRYPTO: `/admin/dashboard/transfer_to_pool_wallet`,
			// pending-fees PAGE
			POST_BUTTONS_PENDING_FEES_CRYPTO: `/admin/dashboard/pending_fee/transfer_to_profit_wallet`,
			POST_BUTTONS_PENDING_FEES_FIAT: `/admin/dashboard/pending_fee/transfer_to_bank`,
			// user-balances PAGE
			POST_BUTTONS_USER_BALANCES_ALL: `/admin/dashboard/transfer_to_pool_wallet/for_all`,
			POST_BUTTONS_USER_BALANCES_TOTALS: `/admin/dashboard/transfer_to_pool_wallet`,
			POST_BUTTONS_USER_BALANCES_WALLETS: (userId: string) =>
				`/admin/user/${userId}/transfer-to-pool`,
			POST_BUTTONS_USER_BALANCES: (userId: string) => `/admin/user/${userId}/transfer-to-pool`,
		},
		// GET_DEPOSIT_ACCOUNTS: '/admin/requisite/get',
		GET_DEPOSIT_ACCOUNTS: '/admin/transactions/fiats/requisite/get',
		GET_USER_DEPOSIT_ACCOUNTS: (userId: number) => `/admin/user/${userId}/requisite/get`,
		UPDATE_DEPOSIT_ACCOUNT: '/admin/requisite/update',
		CREATE_DEPOSIT_ACCOUNT: '/admin/requisite/create',
		DELETE_DEPOSIT_ACCOUNT: '/admin/requisite/delete',
	},
	global: {
		GET_GLOBAL_ASSETS: () => `/admin/assets`,
	},
	download: {
		GET_DOWNLOAD_KEY: '/generate_secret_for_download_file',
		GET_FILE_DOWNLOAD_DEPOSIT_WITHDRAW: (
			currency: string,
			idUser: number,
			fileName: string,
			key: string,
		) => `/transactions/${currency}/report/${idUser}/${fileName}/${key}`,
		GET_FILE_DOWNLOAD_DEPOSIT_TRADE: (idUser: string | number, fileType: string, key: string) =>
			`/trade/report/trades/${idUser}/${fileType}/${key}`,
		GET_FILE_DOWNLOAD_LEDGER: (adminId: string, extension: string) =>
			`/admin/transactions/ledger/report/${adminId}/${extension}`,
	},
	support: '/support',
	detailedView: {
		GET_TRADE_DETAILS: (id: number) => `/admin/transactions/trade/detailed-view/${id}`,
		UPDATE_TRADE_DETAILS: '/admin/transactions/trade/detailed-view/update/',
		DELETE_FILES_TRADE_DETAILS: '/admin/transactions/trade/files-delete/',

		GET_FIAT_DETAILS: (id: number) => `/admin/transactions/fiats/detailed-view/${id}`,
		UPDATE_FIAT_DETAILS: '/admin/transactions/fiats/detailed-view/update/',
		DELETE_FILES_FIAT_DETAILS: '/admin/transactions/fiats/files-delete/',

		GET_CRYPTO_DETAILS: (id: number) => `/admin/transactions/crypto/detailed-view/${id}`,
		UPDATE_CRYPTO_DETAILS: '/admin/transactions/crypto/detailed-view/update/',
		DELETE_FILES_CRYPTO_DETAILS: '/admin/transactions/crypto/files-delete/',
		GET_CRYPTO_FILE: (file: string) => `admin/cloud/trs/crypto/download/${file}`,
		GET_FIAT_FILE: (file: string) => `admin/cloud/trs/fiat/download/${file}`,
		GET_TRADE_FILE: (file: string) => `admin/cloud/trs/trade/download/${file}`,
	},
	balances: {
		UPDATE_BALANCES: (id: number | string) => `/admin/update/balance/${id}`,
	},
	messages: {
		GET_MESSAGES: '/admin/messages/',
		GET_ONE_MESSAGE: (id: number) => `/admin/messages/${id}`,
		CREATE_MESSAGE: '/admin/messages/create',
		DELETE_MESSAGE: '/admin/messages/delete',
		UPDATE_MESSAGE: '/admin/messages/update',
		DELETE_FILES: '/admin/messages/deleteFiles',
		DOWNLOAD_FILES: '/admin/messages/downloadFiles',
		CLONE_MESSAGE: '/admin/messages/cloneMessage',
	},
	logs: {
		GET_EXTERNAL_TRADE_LOGS: '/admin/logs/trade',
		GET_INTERNAL_TRADE_LOGS: '/admin/logs/internal_trade',
	},
};
