import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IUserWalletsItem, IWalletChains } from 'services/api/users/types';
import { useParams } from 'react-router';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { v4 as uuidv4 } from 'uuid';
import { getUserLayers } from 'redux/reducers/users/selectors';
import { ILayersData, layersFilter } from 'utils/layersFilter';
import { ECoinType } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const WalletTableRow: FC<IUserWalletsItem> = ({ asset, balance, balance_eur, type, address }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const permissions = useSelector(getPermissions);

	const layers = useSelector(getUserLayers);

	const layersData: ILayersData | Record<string, any> = layersFilter(
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		`${asset.code}_eur`,
		layers,
	);
	return (
		<>
			{balance === 0 &&
			!asset?.withdrawable &&
			!asset?.depositable &&
			!asset.exchangeable ? null : (
				<div className={`tr ${type === ECoinType.CRYPTO ? 'cryptoRow' : 'fiatRow'} `}>
					<div className="td">
						<div className="td-hidden-name">
							{type === ECoinType.CRYPTO ? 'Cryptocurrency' : 'Currency'}
						</div>
						{type === ECoinType.CRYPTO ? (
							<>
								<div className="crypto-flex">
									{asset?.chains?.map((chain: IWalletChains) => {
										return chain.id !== 7 ? (
											<div className="coin coin--type12">
												<div className="coin__icon">
													<img
														// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
														src={`/img/currencies/${asset.code.toLowerCase()}.svg`}
														alt={asset.code}
													/>
												</div>
												<div className="coin__text-wrap">
													<p key={uuidv4()} className="coin__text">
														{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
														{`${asset?.code?.toUpperCase()} ${
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															asset?.chains.length > 1 ? `(${String(chain.code)})` : ''
														}`}{' '}
													</p>
													<span className="coin__text-more">{asset?.name}</span>
												</div>
											</div>
										) : null;
									})}
								</div>
							</>
						) : (
							<div className="flex">
								<div className="coin__icon">
									<img
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										src={`/img/currencies/${asset.code.toLowerCase()}.svg`}
										alt={asset.code}
									/>
								</div>
								<div className="coin__icon___text">
									<p>{asset?.code?.toUpperCase()}</p>
									<span>{asset?.name}</span>
								</div>
							</div>
						)}
					</div>

					<div
						className={`td flex-end ${type === ECoinType.FIAT ? 'td--right' : ''} ${
							type === ECoinType.CRYPTO ? 'platform-balance' : 'total'
						}`}
					>
						<div className="td-hidden-name">
							{type === ECoinType.CRYPTO ? 'Platform Balance' : 'Total'}
						</div>
						{type === ECoinType.CRYPTO ? (
							<p>
								{toFixedNumber(balance, asset?.code, true)}
								{/* {address.reduce((acc, item) => acc + item.fireblocks_balance, 0)} */}
								<br />
								<span>
									{' '}
									{layersData?.data
										? toFixedNumber(balance * Number(layersData?.data?.sell[1]), '', true)
										: toFixedNumber(balance_eur, 'fiat', true)}{' '}
									EUR
								</span>
							</p>
						) : (
							<p>
								{toFixedNumber(balance, '', true)}
								<br />
								<span className="span-fiat">
									{' '}
									{layersData?.data
										? toFixedNumber(balance / Number(layersData?.data?.sell[1]), '', true)
										: toFixedNumber(balance_eur, 'fiat', true)}{' '}
									EUR
								</span>
							</p>
						)}
					</div>
					{type === ECoinType.CRYPTO && (
						<>
							<div className="td flex-end">
								<div className="td-hidden-name">Deposit Wallet</div>
								{asset?.chains?.map((e) => {
									return e.id !== 7 ? (
										<p key={e.id}>
											{toFixedNumber(e?.balances?.balance, asset?.code, true)}
											<br />
											<span>
												{layersData?.data
													? toFixedNumber(
															Number(e?.balances?.balance) * Number(layersData?.data?.sell[1]),
															'',
															true,
													  )
													: toFixedNumber(e?.balances?.balance_eur, 'fiat', true)}{' '}
												EUR
											</span>
										</p>
									) : null;
								})}
							</div>
							<div className="td td--right">
								<div className="buttonsChains">
									{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && (
										<>
											{asset?.chains?.map((chain) => {
												return chain.id !== 7 ? (
													<button
														key={chain.id}
														onClick={() => {
															const data = {
																title: `Send to Pool Wallet`,
																// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																currency: `${asset.code.toUpperCase()} (${String(chain.code)})`,
																depositAmount: `${toFixedNumber(
																	chain.balances?.balance,
																	'crypto',
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																)} ${asset.code.toUpperCase()}`,
																walletName: `Pool Wallet ${
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																	balance > 1 ? chain.code : ''
																}`,
																payload: {
																	type: 'User balances deposit wallet totals user-management',
																	asset_id: asset.id,
																	chain_id: chain.id,
																	user_id: userId,
																},

																total_balance: chain?.balances?.balance,
															};
															dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
															dispatch(setPopUpData(data));
														}}
														type="button"
														className="btn btn-outline-secondary btn-outline-secondary--sm"
													>
														Send to Pool Wallet {chain?.code}
													</button>
												) : null;
											})}
										</>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default WalletTableRow;
