import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import useWebSocket from 'react-use-websocket';
import { useDispatch } from 'react-redux';
import { dashboardInitialState, updateDashboardLayers } from 'redux/reducers/dashboard/reducer';
import { useEffect, useLayoutEffect, useState } from 'react';
import CryptoTab from './Crypto';
import FiatTab from './Fiat';

const AdminPanel = () => {
	const dispatch = useDispatch();
	const [socketUrl, setSocketUrl] = useState(process.env.REACT_APP_SOCKET_URL as string);
	const { lastMessage } = useWebSocket(socketUrl);

	useLayoutEffect(() => {
		dispatch(dashboardInitialState());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (lastMessage?.data) {
			dispatch(updateDashboardLayers(JSON.parse(lastMessage?.data)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastMessage]);

	return (
		<div className="admin-panel">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Dashboard</p>
				</div>
			</div>
			<div className="tabs-buttons mb-16">
				<NavLink
					onClick={() => {
						dispatch(dashboardInitialState());
					}}
					to={ROUTES.adminPanel.currency.crypto}
				>
					Crypto
				</NavLink>
				<NavLink
					onClick={() => {
						dispatch(dashboardInitialState());
					}}
					to={ROUTES.adminPanel.currency.fiat}
				>
					Fiat
				</NavLink>
			</div>
			<Switch>
				<Route path={ROUTES.adminPanel.currency.crypto} component={CryptoTab} />
				<Route path={ROUTES.adminPanel.currency.fiat} component={FiatTab} />
				<Redirect to={ROUTES.adminPanel.currency.crypto} />
			</Switch>
		</div>
	);
};

export default AdminPanel;
