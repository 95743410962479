import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

interface ISelectDate {
	dateValue?: string | null;
	type?: string | null;
	onSwitch?: ((value: boolean) => void) | null;
	duplicate?: boolean;
	cloneMessage: boolean;
}

const SelectDate: FC<ISelectDate> = ({ dateValue, duplicate, cloneMessage, type, onSwitch }) => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const { setFieldValue } = useFormikContext();
	const handleChangeDate = (date: Date) => {
		setStartDate(date);
		setFieldValue('date', moment(date).format('YYYY-MM-DD HH:mm'));
	};

	const [sendNow, setSendNow] = useState(true);

	const handleSendNowToogle = () => {
		if (sendNow) {
			setSendNow(!sendNow);
			onSwitch && onSwitch(!sendNow);
		} else {
			setSendNow(!sendNow);
			onSwitch && onSwitch(!sendNow);
			const date = new Date(Date.now());
			setStartDate(date);
			setFieldValue('date', moment(date).format('YYYY-MM-DD HH:mm:ss'));
		}
	};

	useEffect(() => {
		if (dateValue) {
			const utcDate = moment.utc(dateValue);
			const localDate = new Date(
				utcDate.year(),
				utcDate.month(),
				utcDate.date(),
				utcDate.hours(),
				utcDate.minutes(),
				utcDate.seconds(),
			);
			setStartDate(localDate);
		} else {
			const date = new Date(Date.now());
			setStartDate(date);
			setFieldValue('date', moment(date).format('YYYY-MM-DD HH:mm:ss'));
		}
		setSendNow(Boolean(!dateValue));
		if (cloneMessage) {
			handleSendNowToogle();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateValue, cloneMessage]);

	const now = moment();

	const setMinTime = () => {
		return moment(startDate).isSame(now, 'day') ? now.toDate() : moment().startOf('day').toDate();
	};

	return (
		<div className="input">
			<div className="input__label--wrapper">
				<p className="input__name">{type === 'message' ? 'Date and time' : 'Date'}</p>
				{type === 'message' && (
					<div className="switch">
						<label className="switch__label">
							<input
								onChange={handleSendNowToogle}
								name="users"
								checked={sendNow}
								type="checkbox"
								className="hidden"
								disabled={duplicate}
							/>
							Send now
							<span className="switch__toggler switch__toggler--message" />
						</label>
					</div>
				)}
			</div>
			<div className="input-wrapper">
				<DatePicker
					selected={startDate}
					onChange={handleChangeDate}
					showTimeSelect
					timeFormat="HH:mm"
					timeIntervals={30}
					minDate={type === 'message' ? now.toDate() : null}
					minTime={type === 'message' ? setMinTime() : moment().startOf('day').toDate()}
					maxTime={moment().endOf('day').toDate()}
					disabled={type === 'message' ? sendNow || duplicate : false}
					dateFormat="yyyy-MM-dd HH:mm"
					renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
						<div className="react-datepicker__header">
							<button
								type="button"
								className="react-datepicker__navigation react-datepicker__navigation--previous"
								aria-label="Previous Month"
								onClick={decreaseMonth}
							>
								<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
									<svg
										width="7"
										height="11"
										viewBox="0 0 7 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.49983 1.38887L5.38984 0.213867L0.666504 5.21387L5.38984 10.2139L6.49983 9.03887L2.89435 5.21387L6.49983 1.38887Z"
											fill="#ffffff"
										/>
									</svg>
								</span>
							</button>
							<button
								type="button"
								className="react-datepicker__navigation react-datepicker__navigation--next"
								aria-label="Next Month"
								onClick={increaseMonth}
							>
								<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
									<svg
										width="7"
										height="11"
										viewBox="0 0 7 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.60998 0.213867L0.5 1.38887L4.10548 5.21387L0.5 9.03887L1.60998 10.2139L6.33332 5.21387L1.60998 0.213867Z"
											fill="#ffffff"
										/>
									</svg>
								</span>
							</button>
							<div className="react-datepicker__current-month">{`${date.toLocaleString('default', {
								month: 'long',
							})} ${date.getFullYear()}`}</div>
						</div>
					)}
				/>
				<div className="input-icon input-icon--bigger input-icon--right">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.99964 13.25C8.58542 13.25 8.24964 13.5858 8.24964 14C8.24964 14.4142 8.58542 14.75 8.99964 14.75H14.9996C15.4138 14.75 15.7496 14.4142 15.7496 14C15.7496 13.5858 15.4138 13.25 14.9996 13.25H8.99964Z"
							fill="#626A85"
						/>
						<path
							d="M8.99964 16.75C8.58542 16.75 8.24964 17.0858 8.24964 17.5C8.24964 17.9142 8.58542 18.25 8.99964 18.25H14.9996C15.4138 18.25 15.7496 17.9142 15.7496 17.5C15.7496 17.0858 15.4138 16.75 14.9996 16.75H8.99964Z"
							fill="#626A85"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M9.74964 2C9.74964 1.58579 9.41385 1.25 8.99964 1.25C8.58542 1.25 8.24964 1.58579 8.24964 2V3.75001H4.5C3.25736 3.75001 2.25 4.75737 2.25 6.00001V21C2.25 22.2426 3.25736 23.25 4.5 23.25H19.5C20.7426 23.25 21.75 22.2426 21.75 21V6.00001C21.75 4.75737 20.7426 3.75001 19.5 3.75001H15.7496V2C15.7496 1.58579 15.4138 1.25 14.9996 1.25C14.5854 1.25 14.2496 1.58579 14.2496 2V3.75001H9.74964V2ZM8.29232 5.25001C8.39528 5.5413 8.67308 5.75 8.99964 5.75C9.32619 5.75 9.604 5.5413 9.70696 5.25001H14.2923C14.3953 5.5413 14.6731 5.75 14.9996 5.75C15.3262 5.75 15.604 5.5413 15.707 5.25001H19.5C19.9142 5.25001 20.25 5.58579 20.25 6.00001V9.25H3.75V6.00001C3.75 5.58579 4.08579 5.25001 4.5 5.25001H8.29232ZM3.75 10.75V21C3.75 21.4142 4.08579 21.75 4.5 21.75H19.5C19.9142 21.75 20.25 21.4142 20.25 21V10.75H3.75Z"
							fill="#626A85"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

SelectDate.defaultProps = { dateValue: null, type: null, onSwitch: null, duplicate: false };

export default SelectDate;
