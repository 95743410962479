import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUpdateCommissionRequest, IReferralsApi, IUpdateReferralUserFeeRequest } from './types';

export const referrals: IReferralsApi = {
	getCommission: () => http.get(endpoint.referrals.COMMISSION).then(({ data }) => data),
	updateCommission: (payload: IUpdateCommissionRequest) =>
		http.put(endpoint.referrals.COMMISSION, payload),
	getFreeReferrals: ({ ...params }) =>
		http.get(endpoint.referrals.GET_FREE_USERS, { params }).then(({ data }) => data),

	getReferrals: ({ ...params }) =>
		http.get(endpoint.referrals.GET_USERS, { params }).then(({ data }) => data),

	getReferralsByUser: ({ userId, ...params }) =>
		http.get(endpoint.referrals.GET_REFERRALS_BY_USER(userId), { params }).then(({ data }) => data),

	addReferralByUser: ({ userId, referral_id }) =>
		http.post(endpoint.referrals.GET_REFERRALS_BY_USER(userId), { referral_id }),

	delReferralByUser: ({ userId, referral_id }) =>
		http.delete(endpoint.referrals.DEL_REFERRAL_BY_USER, {
			data: { user_id: userId, referral_id },
		}),

	getReferralsFile: ({ fileType, token, userId }) =>
		http.get(endpoint.referrals.GET_REFERRALS_FILE(fileType, token || '', userId)),

	updateReferralUserFee: (payload: IUpdateReferralUserFeeRequest) =>
		http.put(endpoint.referrals.UPDATE_REFERRAL_USER_FEE, payload),
};
