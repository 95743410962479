import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import InputFile from 'ui/Formik/InputFile/InputFile';
import { CloseFileIcon } from 'assets/inline-svg';
import handleUploadIcon from 'ui/Formik/InputFile/config';

import {
	createTradeDetailsRequest,
	deleteTradeDetailsFilesRequest,
	downloadTradeFileRequest,
} from '../../../redux/reducers/detailedView/reducer';

interface ITradeDetailsForm {
	item: any;
}

const TradeDetailsForm: React.FC<ITradeDetailsForm> = ({ item }) => {
	const dispatch = useDispatch();
	const [fileList, setFileList] = useState<any>([]);

	const initialValues = {
		client_notes: item.client_notes || '',
		admin_notes: item.admin_notes || '',
	};

	const validationSchema = yup.object().shape({
		admin_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const deleteFilesHandler = (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		file: string,
	) => {
		event.stopPropagation();
		const payload = {
			id: item.id,
			files: [file],
		};
		dispatch(deleteTradeDetailsFilesRequest(payload));
		// console.log(payload);
	};
	const handleDownloadFile = (file: string) => {
		dispatch(downloadTradeFileRequest({ file: file.split('/')[0] }));
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
						admin_notes: value.admin_notes,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createTradeDetailsRequest({
							id: item.id,
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
					setTouched,
				}) => (
					<>
						<Form>
							<Field
								title="Admin Notes"
								type="textarea"
								placeholder=""
								name="admin_notes"
								component={Input}
								onBlur={() => setFieldTouched('admin_notes')}
							/>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
								onBlur={() => setFieldTouched('client_notes')}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>

							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input-file-preview">
									{item.files?.length > 0 ? (
										item.files.map((file: any) => {
											return (
												<div
													key={file}
													className="input-file-preview__item"
													onClick={() => handleDownloadFile(file)}
												>
													<img src={handleUploadIcon(file.split('.')[1])} alt="" />
													<span className="input-file-preview__name" title={file}>
														{file}
													</span>
													<span
														className="input-file-preview__item__del"
														onClick={(event) => deleteFilesHandler(event, file)}
													>
														<CloseFileIcon />
													</span>
												</div>
											);
										})
									) : (
										<div>No data</div>
									)}
								</div>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default TradeDetailsForm;
