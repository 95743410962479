import React, { useEffect } from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import DetailedView from 'components/DetailedView/DetailedView';
import { resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from 'redux/reducers/transactionsFilters/types';
import { useDispatch } from 'react-redux';
import CryptoHistory from '../CryptoHistory';
import FiatHistory from '../FiatHistory';
import TradeHistory from '../TradeHistory';

const TransactionHistoryTabView = () => {
	const { url, path } = useRouteMatch();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<Switch>
					<Route exact path={`${path}/trade`} component={TradeHistory} />
					<Route exact path={`${path}/trade/:id`} component={DetailedView} />

					<Redirect to={`${path}/trade`} />
				</Switch>
			</div>
		</div>
	);
};

export default TransactionHistoryTabView;
