import { useEffect, useLayoutEffect, useRef } from 'react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { useDispatch } from 'react-redux';
import { resetDetailedQuery, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from 'redux/reducers/transactionsFilters/types';
import DetailedView from 'components/DetailedView/DetailedView';
import CryptoHistory from '../CryptoHistory';
import FiatHistory from '../FiatHistory';

const TransferHistoryTabView = () => {
	const { url, path } = useRouteMatch();
	const { state, pathname } = useLocation();
	const dispatch = useDispatch();
	const prevSection = useRef<string | null>(null);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMainSection = (pathName: string) => {
		const regex = /\/user-management\/\d+\/(transfer-history)(?:\/(crypto|fiat))?/;
		const match = regex.exec(pathName);

		if (match) {
			return match[2] ? `${match[1]}/${match[2]}` : match[1];
		}

		return null;
	};

	useEffect(() => {
		const currentSection = getMainSection(pathname);

		if (prevSection.current && prevSection.current !== currentSection) {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT));
			dispatch(resetDetailedQuery());
		}
		prevSection.current = currentSection;
	}, [dispatch, pathname]);

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				{!state && (
					<div className="tabs-buttons tabs-buttons--transfer-history">
						<NavLink to={`${url}/crypto`} className="button button--size4 button--type3">
							Crypto
						</NavLink>
						<NavLink to={`${url}/fiat`} className="button button--size4 button--type3">
							Fiat
						</NavLink>
					</div>
				)}
				<Switch>
					<Route exact path={`${path}/crypto`} component={CryptoHistory} />
					<Route exact path={`${path}/fiat`} component={FiatHistory} />
					<Route path={`${path}/crypto/:id`} component={DetailedView} />
					<Route path={`${path}/fiat/:id`} component={DetailedView} />
					<Redirect to={`${path}/crypto`} />
				</Switch>
			</div>
		</div>
	);
};

export default TransferHistoryTabView;
