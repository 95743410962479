import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILayersData, layersFilter } from 'utils/layersFilter';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import {
	getWalletBalancePlatformBalancesUserCrypto,
	getWalletBalancePlatformBalancesUserFiat,
	getWalletsLayers,
} from '../../../../redux/reducers/walletBalance/selectors';
import { popUpOpen, setPopUpData } from '../../../../redux/reducers/popUp/reducer';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const TotalFiat = ({ permission, activeTab }: { permission: boolean; activeTab: string }) => {
	const dispatch = useDispatch();
	const itemFiat = useSelector(getWalletBalancePlatformBalancesUserFiat);
	const itemCrypto = useSelector(getWalletBalancePlatformBalancesUserCrypto);

	const layers = useSelector(getWalletsLayers);

	const layersData: ILayersData | Record<string, any> = layersFilter('btc_eur', layers);

	const fiatBalance = useMemo(() => {
		return itemFiat?.balances.data.reduce((acc: number, item: any) => {
			const layerData: ILayersData | Record<string, any> = layersFilter(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`${item.asset_code}_eur`,
				layers,
			);
			if (layerData?.data && item.balance) {
				return acc + Number(item?.balance) / Number(layerData?.data?.sell[1]);
			}
			return acc + Number(item?.balance_eur);
		}, 0);
	}, [layers, itemFiat]);

	const totalBalanceCrypto = useMemo(() => {
		const cryptoBalance = itemCrypto?.totalBalancesCrypto.btc;

		if (layersData?.data && cryptoBalance)
			return Number(cryptoBalance) + Number(fiatBalance) / Number(layersData?.data?.sell[1]);
		return 0;
	}, [fiatBalance, itemCrypto, layersData?.data]);

	return (
		<>
			<div className="wallet-content-balance flex">
				<div className="wallet-content-balance-item">
					<div className="wallet-content-balance-item__title">
						<p>Estimates Balance</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{toFixedNumber(
								Number(totalBalanceCrypto) * Number(layersData?.data?.sell[1]),
								'EUR',
								true,
							)}
							<span>EUR</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>
							≈ {toFixedNumber(Number(totalBalanceCrypto), 'BTC', true)}
							<span>BTC</span>
						</p>
					</div>
				</div>
				<div className="wallet-content-balance-item ">
					<div className="wallet-content-balance-item__title">
						<p>Fiat Balance</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{toFixedNumber(Number(fiatBalance), 'EUR', true)}
							<span>EUR</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>
							≈ {toFixedNumber(fiatBalance / Number(layersData?.data?.sell[1]), 'BTC', true)}
							<span>BTC</span>
						</p>
					</div>
				</div>
				<div className="wallet-content-balance-item ">
					<div className="wallet-content-balance-item__title">
						<p>Crypto Balance</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{toFixedNumber(
								Number(itemCrypto?.totalBalancesCrypto.btc) * Number(layersData?.data?.sell[1]),
								'EUR',
								true,
							)}
							<span>EUR</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>
							≈ {toFixedNumber(itemCrypto?.totalBalancesCrypto.btc, 'BTC', true)}
							<span>BTC</span>
						</p>
					</div>
				</div>
			</div>
			{activeTab === 'fiat' ? null : (
				<>
					{permission && (
						<button
							onClick={() => {
								const data = {
									title: 'Send All to Pool Wallet',
									payload: {
										type: 'User balances all',
									},
								};
								dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
								dispatch(setPopUpData(data));
							}}
							type="button"
							className="button button--long button--size2"
						>
							Send All to Pool Wallet
						</button>
					)}
				</>
			)}
		</>
	);
};
export default TotalFiat;
