// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import ReactPaginate from 'react-paginate';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IReferralsUserRequest } from 'services/api/referrals/types';
import { ROUTES } from 'routes/constants';
import { getOneUser, getOneUserLoading } from 'redux/reducers/users/selectors';
import { getOneUserRequest, updateUserLayers } from 'redux/reducers/users/reducer';
import { getReferralFilters } from 'redux/reducers/transactionsFilters/selectors';
import {
	getReferralsOneUser,
	getReferralsOneUserLoading,
} from 'redux/reducers/referrals/selectors';
import {
	getReferralsByUserRequest,
	getReferralsFileRequest,
	getReferralsFileUserIdRequest,
} from 'redux/reducers/referrals/reducer';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { ArrowLeftIcon, CSVFileIcon, PlusIcon, XLSFileIcon } from 'assets/inline-svg';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import IconSvg from 'ui/Svg/IconSvg';
import useWebSocket from 'react-use-websocket';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import ReferralsByUserTableHeader from 'components/tables/ReferralsByUserTable/ReferralsByUserTableHeader';
import ReferralsByUserTableRow from 'components/tables/ReferralsByUserTable/ReferralsByUserTableRow';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import AddReferral from '../../../layouts-elements/PopUp/AddReferral';
import ReferralsTableRow from '../../tables/ReferralsTable/ReferralsTableRow';
import Loading from '../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
];

const ReferralsByUser = () => {
	const dispatch = useDispatch();
	const activeFilters = useSelector(getReferralFilters);
	const referrals = useSelector(getReferralsOneUser);
	const loading = useSelector(getReferralsOneUserLoading);

	const user = useSelector(getOneUser);
	const loadingUser = useSelector(getOneUserLoading);
	const permission = useSelector(getPermissions)?.[EPermissionNames.REFERRAL_MANAGEMENT].editable;
	const { userId } = useParams<{ userId: string }>();

	const [currentPage, setCurrentPage] = useState(referrals?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const [pageCount, setPageCount] = useState(1);
	const referralsLoadingState = useSelector((state: any) => state.referrals.loadingFreeReferrals);
	const { lastMessage } = useWebSocket(process.env.REACT_APP_SOCKET_URL as string);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || referrals?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	// const pageCount = referrals?.last_page ? referrals.last_page : 1;

	useLayoutEffect(() => {
		// if (currentPage > pageCount) {
		// 	setCurrentPage(1);
		// }
		setPageCount(referrals?.last_page ? referrals.last_page : 1);
	}, [referrals?.last_page]);

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
	}, [currentPage, pageCount]);

	const previousParams = useRef();

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params = {
			userId,
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		if (JSON.stringify(params) !== JSON.stringify(previousParams.current)) {
			dispatch(getReferralsByUserRequest(params));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			previousParams.current = params;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, currentPage, perPage, referralsLoadingState]);

	useLayoutEffect(() => {
		dispatch(getOneUserRequest({ userId }));
		return () => {
			dispatch(resetFilters(EFiltersType.REFERRAL_FILTERS));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (lastMessage?.data) {
			dispatch(updateUserLayers(JSON.parse(lastMessage?.data)));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastMessage]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.REFERRAL_FILTERS }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.REFERRAL_FILTERS));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.REFERRAL_FILTERS,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.REFERRAL_FILTERS,
				}),
			);
		}
	};

	const handleDownloadCsvFile = () => {
		dispatch(getReferralsFileUserIdRequest({ userId, fileType: 'csv' }));
	};

	const handleDownloadXlsxFile = () => {
		dispatch(getReferralsFileUserIdRequest({ userId, fileType: 'xlsx' }));
	};
	const [addReferralPopupOpened, setAddReferralPopupOpened] = useState(false);

	const handleCloseAddReferralPopup = () => {
		setAddReferralPopupOpened(false);
	};
	const handleOpenAddReferralPopup = () => {
		setAddReferralPopupOpened(true);
	};

	return (
		<>
			<div className="tabs-buttons tabs-buttons--referral">
				<NavLink to={ROUTES.referralSystem.referrals}>Referrals</NavLink>

				<NavLink to={ROUTES.referralSystem.trades}>Trades</NavLink>
			</div>

			<div className="title-block mb-20">
				<Link to={ROUTES.referralSystem.referrals} className="btn btn--icon btn--back" />
				<p className="table-title">
					{!loadingUser ? (
						<>
							{' '}
							{user?.data.first_name} {user?.data.last_name} Referrals
						</>
					) : (
						<Loading height={25} />
					)}
				</p>
			</div>

			<div className="table-filter table-filter--referral">
				<SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
				/>
				{!!permission && (
					<button
						type="button"
						onClick={handleOpenAddReferralPopup}
						className="btn btn-primary btn-primary--sm"
					>
						<PlusIcon />
						Add Referral
					</button>
				)}
			</div>

			{/* <ActiveFilters
				activeFilters={activeFilters}
				handleDeleteFilter={handleDeleteFilter}
				handleResetFilters={handleResetFilters}
			/> */}
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--referral-system-referrals-by-user">
						<div className="table-header">
							<ReferralsByUserTableHeader />
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{referrals?.data?.length ? (
										<>
											{referrals?.data.map((item: any) => {
												return (
													<ReferralsByUserTableRow
														key={item.id}
														{...item}
														permission={!!permission}
													/>
												);
											})}
										</>
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{!!referrals?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									<ReactPaginate
										breakLabel="..."
										pageCount={pageCount}
										onPageChange={handlePageClick}
										forcePage={currentPage - 1}
										className="pagination"
										activeClassName="active"
										previousClassName="pagination__arrow pagination__arrow--prev"
										nextClassName="pagination__arrow pagination__arrow--next"
										previousLabel=""
										nextLabel=""
									/>
									<div className="fileButton">
										{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
										<button
											type="button"
											className="btn btn--csv"
											onClick={handleDownloadCsvFile}
										/>
										{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
										<button
											type="button"
											className="btn btn--xlsx"
											onClick={handleDownloadXlsxFile}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!referrals?.data.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<ReactPaginate
							breakLabel="..."
							pageCount={pageCount}
							onPageChange={handlePageClick}
							forcePage={currentPage - 1}
							className="pagination"
							activeClassName="active"
							previousClassName="arrow"
							nextClassName="arrow"
							previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
							nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
						/>
					)}
				</div>
			)} */}
			<AddReferral open={addReferralPopupOpened} closeModal={handleCloseAddReferralPopup} />
		</>
	);
};

export default ReferralsByUser;
