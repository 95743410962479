import React, { FC } from 'react';
import classNames from 'classnames';
import { roundingNumber } from 'services/utils/roundingNumber';
import { toFixedNumber } from 'services/utils/toFixedNumber';
import { getDashboardLayers } from 'redux/reducers/dashboard/selectors';
import { useSelector } from 'react-redux';
import { ILayersData, layersFilter } from 'utils/layersFilter';
import { IBalanceListProps } from './types';

const BalanceList: FC<IBalanceListProps> = ({ list, activeIndex, onChangeActive, tab }) => {
	const layers = useSelector(getDashboardLayers);
	return (
		<div className="balance-info-list">
			{list?.map((item, index) => {
				const layerData: ILayersData | Record<string, any> = layersFilter(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`${item?.code.toLowerCase()}_eur`,
					layers,
				);
				return (
					item.chainId !== 7 && (
						<div
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							key={`${item.name}-${item?.network}`}
							className={classNames('balance-info-list__items', { active: index === activeIndex })}
							onClick={() => onChangeActive(index)}
						>
							<div className="box-info">
								<div className="box-info_img">
									{/* <img
								src={item?.img?.replace('oneify-stage.corp.merehead.xyz', 'dev1.oneify.cybro.cz')}
								alt={item?.code}
							/> */}
									{!!item?.code && (
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										<img src={`/img/currencies/${item?.code.toLowerCase()}.svg`} alt={item?.name} />
									)}
								</div>
								<div className="box-info_name">
									{item?.code && item?.name && (
										<>
											<span className="box-info_name__code">{item.code}</span>
											{item.chainId && (item.code === 'USDC' || item.code === 'USDT') && (
												<span className="box-info_name__code">({item.network})</span>
											)}
											<span className="box-info_name__name">{item.name}</span>
										</>
									)}
								</div>
							</div>
							<div className="box-info-coin">
								<p className="text-sm-center">
									{item?.code && item?.valueCrypto && (
										<>
											{toFixedNumber(item.valueCrypto.toFixed(10), item.code, true)}{' '}
											{String(item.code)}
										</>
									)}
								</p>
							</div>
							<div className="box-info-coin">
								<p className="text-sm-right">
									{layerData?.data ? (
										<>
											{toFixedNumber(
												tab === 'crypto'
													? item.valueCrypto * Number(layerData?.data?.sell[1])
													: item.valueCrypto / Number(layerData?.data?.sell[1]),
												'EUR',
												true,
											)}{' '}
											EUR
										</>
									) : (
										<>{toFixedNumber(item.valueEuro, 'EUR', true)} EUR</>
									)}
								</p>
							</div>
						</div>
					)
				);
			})}
		</div>
	);
};

export default BalanceList;
