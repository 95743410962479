import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import DetailedView from 'components/DetailedView/DetailedView';
import { useDispatch } from 'react-redux';
import { EFiltersType } from 'redux/reducers/transactionsFilters/types';
import { resetDetailedQuery, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import TradesTable from './TradeTable';

const TradesTab = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
			dispatch(resetDetailedQuery());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="user-management">
			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.trades.index}>
					<TradesTable />
				</Route>
				<Route
					path={`${ROUTES.transfersAndTradeReports.trades.index}/:id`}
					component={DetailedView}
				/>
				<Redirect to={ROUTES.transfersAndTradeReports.trades.index} />
			</Switch>
		</div>
	);
};
export default TradesTab;
