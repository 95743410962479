import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import DepositCryptoDetails from './DepositCryptoDetails/DepositCryptoDetails';
import { IStateLocation } from './types';
import WithdrawalCryptoDetails from './WithdrawalCryptoDetails/WithrawalCryptoDetails';
import DepositCryptoPendingDetails from './DepositCryptoDetails/DepositCryptoPendingDetails';
import DepositFiatDetails from './DepositFiatDetails/DepositFiatDetails';
import WithdrawalFiatDetails from './WithdrawalFiatDetails/WithdrawalFiatDetails';
import TransfersFiatDetails from './TransfersFiatDetails/TransfersFiatDetails';
import TransfersFiatDetailsUser from './TransfersFiatDetailsUser/TransfersFiatDetailsUser';
import TransfersCryptoDetails from './TransfersCryptoDetails/TransfersCryptoDetails';
import TradeDetails from './TradeDetails/TradeDetails';

const DetailedView = () => {
	const { state, pathname } = useLocation<IStateLocation>();

	const history = useHistory();
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (!state) {
			history.replace(pathname.substring(0, pathname.lastIndexOf('/')));
		}
	}, [history, pathname, state]);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (
		state &&
		(state.fromDepositCryptoReports || state.fromDepositCryptoUser || state.fromLedgerDepositCrypto)
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositCryptoDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (state && state.fromPendingDepositCrypto) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositCryptoPendingDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (
		state &&
		(state.fromWithdrawalCryptoReports ||
			state.fromWithdrawalCryptoUser ||
			state.fromPendingWithdrawalCrypto ||
			state.fromLedgerWithdrawalCrypto)
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<WithdrawalCryptoDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (
		state &&
		(state.fromDepositFiatReports || state.fromDepositFiatUser || state.fromLedgerDepositFiat)
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositFiatDetails txId={state.txId} />
					</div>
				</div>
			</>
		);
	}

	if (state && state.fromPendingDepositFiat) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositFiatDetails txId={state.txId} />
					</div>
				</div>
			</>
		);
	}

	if (
		state &&
		(state.fromWithdrawalFiatReports ||
			state.fromWithdrawalFiatUser ||
			state.fromPendingWithdrawalFiat ||
			state.fromLedgerWithdrawalFiat)
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<WithdrawalFiatDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (state && state.fromTransfersFiat) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<TransfersFiatDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (state && (state.fromTransfersCrypto || state.fromTransfersCryptoUser)) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<TransfersCryptoDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (state && state.fromTransfersFiatUser) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<TransfersFiatDetailsUser txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	if (state && (state.fromTradeReports || state.fromTradeUser || state.fromLedgerTrade)) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<TradeDetails txId={state.txId} userId={state.userId} />
					</div>
				</div>
			</>
		);
	}

	return <div>No Data</div>;
};

export default DetailedView;
