import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { useDispatch } from 'react-redux';
import { resetDetailedQuery, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from 'redux/reducers/transactionsFilters/types';
import DetailedView from 'components/DetailedView/DetailedView';
import CryptoHistory from '../CryptoHistory';
import FiatHistory from '../FiatHistory';

interface IDepositHistoryTabViewProp {
	type: string;
}

const DepositHistoryTabView: FC<IDepositHistoryTabViewProp> = () => {
	const { url, path } = useRouteMatch();
	const location = useLocation();
	const dispatch = useDispatch();
	const { state } = useLocation();

	const prevSection = useRef<string | null>(null);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMainSection = (pathname: string) => {
		const regex =
			/\/user-management\/\d+\/(deposit-history|withdrawal-history)(?:\/(crypto|fiat))?/;
		const match = regex.exec(pathname);

		if (match) {
			return match[2] ? `${match[1]}/${match[2]}` : match[1];
		}

		return null;
	};

	useEffect(() => {
		const currentSection = getMainSection(location.pathname);

		if (prevSection.current && prevSection.current !== currentSection) {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT));
			dispatch(resetDetailedQuery());
		}
		prevSection.current = currentSection;
	}, [dispatch, location.pathname]);

	const type = url.replace(/(?:deposit|withdrawal)|./gi, (match) => {
		return match === 'deposit' || match === 'withdrawal' ? match : '';
	});

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div className="tabs-buttons tabs-buttons--transfer-history">
					{!state && (
						<>
							<NavLink to={`${url}/crypto`} className="button button--size4 button--type3">
								Crypto
							</NavLink>
							<NavLink to={`${url}/fiat`} className="button button--size4 button--type3">
								Fiat
							</NavLink>
						</>
					)}
				</div>
				<Switch>
					<Route
						exact
						path={`${path}/crypto`}
						render={(routeProps) => <CryptoHistory type={type} {...routeProps} />}
					/>
					<Route
						exact
						path={`${path}/fiat`}
						render={(routeProps) => <FiatHistory type={type} {...routeProps} />}
					/>
					<Route path={`${url}/:id`} component={DetailedView} />

					<Redirect to={`${path}/crypto`} />
				</Switch>
			</div>
		</div>
	);
};

export default DepositHistoryTabView;
