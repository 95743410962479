import React, { useEffect, useRef } from 'react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EPendingWithdrawalType } from 'services/api/transactions/types';
import DetailedView from 'components/DetailedView/DetailedView';
import { useDispatch } from 'react-redux';
import { resetDetailedQuery, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from 'redux/reducers/transactionsFilters/types';
import PendingWithdrawalTable from './PendingWithdrawalTable';

const PendingWithdrawalTab = () => {
	const { path: matchPath } = useRouteMatch();
	const { state, pathname } = useLocation();

	const dispatch = useDispatch();
	const prevSection = useRef<string | null>(null);

	const getMainSection = (pathName: string) => {
		const regex = /\/transfers-and-trade-reports\/(pending-withdrawal)(?:\/(crypto|fiat))?/;

		const match = regex.exec(pathName);

		if (match) {
			return match[2] ? `${match[1]}/${match[2]}` : match[1];
		}

		return null;
	};
	useEffect(() => {
		const currentSection = getMainSection(pathname);
		if (prevSection.current && prevSection.current !== currentSection) {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS));
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS));
			dispatch(resetDetailedQuery());
		}
		prevSection.current = currentSection;
	}, [dispatch, pathname]);
	return (
		<div className="user-management">
			{!state && (
				<div className="tabs-buttons-wrapper">
					<div className="tabs-buttons tabs-buttons--pending">
						<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto}>Crypto</NavLink>
						<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.fiat}>Fiat</NavLink>
					</div>
				</div>
			)}
			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto}>
					<PendingWithdrawalTable type={EPendingWithdrawalType.CRYPTO} />
				</Route>
				<Route exact path={ROUTES.transfersAndTradeReports.pendingWithdrawal.fiat}>
					<PendingWithdrawalTable type={EPendingWithdrawalType.FIAT} />
				</Route>
				<Route path={`${matchPath}/:id`} component={DetailedView} />
				<Redirect to={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto} />
			</Switch>
		</div>
	);
};
export default PendingWithdrawalTab;
