import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILayersData, layersFilter } from 'utils/layersFilter';
import {
	getWalletBalancePlatformBalancesUserCrypto,
	getWalletBalancePlatformBalancesUserCryptoLoading,
	getWalletBalancePlatformBalancesUserFiat,
	getWalletsLayers,
} from '../../../../redux/reducers/walletBalance/selectors';
import { popUpOpen, setPopUpData } from '../../../../redux/reducers/popUp/reducer';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import Loading from '../../../../layouts-elements/Loading/Loading';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const Total = ({ permission, activeTab }: { permission: boolean; activeTab: string }) => {
	const dispatch = useDispatch();
	const itemCrypto = useSelector(getWalletBalancePlatformBalancesUserCrypto);
	const loading = useSelector(getWalletBalancePlatformBalancesUserCryptoLoading);
	const itemFiat = useSelector(getWalletBalancePlatformBalancesUserFiat);

	const layers = useSelector(getWalletsLayers);

	const layersData: ILayersData | Record<string, any> = layersFilter('btc_eur', layers);

	const fiatBalance = useMemo(() => {
		return itemFiat?.balances.data.reduce((acc: number, item: any) => {
			const layerData: ILayersData | Record<string, any> = layersFilter(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`${item.asset_code}_eur`,
				layers,
			);
			if (layerData?.data && item.balance) {
				return acc + Number(item?.balance) / Number(layerData?.data?.sell[1]);
			}
			return acc + Number(item?.balance_eur);
		}, 0);
	}, [layers, itemFiat]);

	const totalBalanceCrypto = useMemo(() => {
		const cryptoBalance = itemCrypto?.totalBalancesCrypto.btc;

		if (layersData?.data && cryptoBalance)
			return Number(cryptoBalance) + Number(fiatBalance) / Number(layersData?.data?.sell[1]);
		return 0;
	}, [fiatBalance, itemCrypto, layersData?.data]);

	return (
		<div className="wallet-content-balance flex">
			<div className="wallet-content-balance-item">
				<div className="wallet-content-balance-item__title">
					<p>Estimates Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{toFixedNumber(
									Number(totalBalanceCrypto) * Number(layersData?.data?.sell[1]),
									'EUR',
									true,
								)}{' '}
								<span> EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {toFixedNumber(Number(totalBalanceCrypto), 'BTC', true)}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>

			<div className="wallet-content-balance-item">
				<div className="wallet-content-balance-item__title">
					<p>Fiat Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{toFixedNumber(Number(fiatBalance), 'EUR', true)}
								<span>EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {toFixedNumber(fiatBalance / Number(layersData?.data?.sell[1]), 'BTC', true)}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>

			<div className="wallet-content-balance-item">
				<div className="wallet-content-balance-item__title">
					<p>Crypto Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{toFixedNumber(
									Number(itemCrypto?.totalBalancesCrypto.btc) * Number(layersData?.data?.sell[1]),
									'EUR',
									true,
								)}
								<span>EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {toFixedNumber(itemCrypto?.totalBalancesCrypto.btc, 'BTC', true)}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Total;
