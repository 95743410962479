import React, { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useDispatch } from 'react-redux';
import { updateUserLayers } from 'redux/reducers/users/reducer';
import BalancesTabView from './BalancesTabView';

const BalancesTab = () => {
	return <BalancesTabView />;
};

export default BalancesTab;
