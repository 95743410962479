import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSatchelConfigurationsRequest } from '../../../../../redux/reducers/users/reducer';

export interface IAccountConfigurations {
	editMode: boolean;
	updateConfDataHandler?: (data: IAccountConfigurationsData) => void;
	userId: number;
}

export interface IAccountConfigurationsData {
	auto_crypto_fiat_exchange: boolean;
	auto_prepaid_card_topup: boolean;
	auto_prepaid_card_popup?: boolean; // Mistake in the backend so we must deal with it
	prepaid_card_uuid: string;
}

const AccountConfigurations: FC<IAccountConfigurations> = ({
	editMode,
	updateConfDataHandler,
	userId,
}) => {
	const dispatch = useDispatch();
	const initialLoad = useRef(true);
	const [inputData, setInputData] = useState<IAccountConfigurationsData>({
		auto_crypto_fiat_exchange: false,
		auto_prepaid_card_topup: false,
		prepaid_card_uuid: '',
	});

	useEffect(() => {
		if (userId) {
			dispatch(
				getSatchelConfigurationsRequest({
					apiParams: {
						userId,
					},
					onFinally: (data: IAccountConfigurationsData) => {
						setInputData({
							auto_crypto_fiat_exchange: data.auto_crypto_fiat_exchange,
							auto_prepaid_card_topup: data.auto_prepaid_card_popup!,
							prepaid_card_uuid: data.prepaid_card_uuid,
						});
					},
				}),
			);
		}
	}, [dispatch, userId]);

	useEffect(() => {
		if (updateConfDataHandler && !initialLoad.current) {
			updateConfDataHandler(inputData);
		}
		initialLoad.current = false;
	}, [inputData, updateConfDataHandler]);

	return (
		<div className="account-list">
			<div className="account-item">
				<div className="account-item__title">
					<p>Configurations</p>
				</div>
				<div className="account-list-item">
					<div className="account-list-item__name">
						<p>Card UUID</p>
					</div>
					<div className="account-list-item__info">
						<div className="input">
							<div className="input-wrapper">
								<input
									disabled={!editMode}
									name="card_uuid"
									className="input-item input-item--user-data"
									type="text"
									value={inputData.prepaid_card_uuid}
									onChange={(e) => {
										setInputData((prevState) => ({
											...prevState,
											prepaid_card_uuid: e.target.value,
										}));
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="account-list-item">
					<div className="account-list-item__info">
						<div className="input">
							<div
								className={
									!editMode ? 'custom-checkbox custom-checkbox--disabled' : 'custom-checkbox'
								}
							>
								<input
									disabled={!editMode}
									type="checkbox"
									value="Allow auto-conversion"
									id="auto_crypto_fiat_exchange"
									name="auto_crypto_fiat_exchange"
									checked={inputData.auto_crypto_fiat_exchange}
									onChange={(e) => {
										setInputData((prevState) => ({
											...prevState,
											auto_crypto_fiat_exchange: e.target.checked,
										}));
									}}
								/>
								<label htmlFor="auto_crypto_fiat_exchange">
									<span className="custom-checkbox__checkbox" />
									<span className="custom-checkbox__label">Allow auto-conversion</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="account-list-item">
					<div className="account-list-item__info">
						<div className="input">
							<div
								className={
									!editMode ? 'custom-checkbox custom-checkbox--disabled' : 'custom-checkbox'
								}
							>
								<input
									disabled={!editMode}
									type="checkbox"
									value="Allow auto-conversion"
									id="auto_prepaid_card_topup"
									name="auto_prepaid_card_topup"
									checked={inputData.auto_prepaid_card_topup}
									onChange={(e) => {
										setInputData((prevState) => ({
											...prevState,
											auto_prepaid_card_topup: e.target.checked,
										}));
									}}
								/>
								<label htmlFor="auto_prepaid_card_topup">
									<span className="custom-checkbox__checkbox" />
									<span className="custom-checkbox__label">Allow card top-up</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountConfigurations;
