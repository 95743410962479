import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { FC } from 'react';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeHistory } from 'redux/reducers/transactions/selectors';
import { addDetailedQuery } from 'redux/reducers/transactionsFilters/reducer';
import { useRouteMatch, Link } from 'react-router-dom';
import { ITradeHistoryRowProps } from '../../CryptoHistory/types';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const TradeHistoryRow: FC<ITradeHistoryRowProps> = ({ item }) => {
	const [date, time] = useFormatDateTime(item.created_at);
	const dispatch = useDispatch();
	const currency = useSelector(getCurrencyData);
	const currentPage = useSelector(getTradeHistory)?.current_page;
	const perPage = useSelector(getTradeHistory)?.per_page;

	const { url } = useRouteMatch();

	const sellCurrency = currency.filter((currencyItem) => currencyItem.id === item.from_asset_id)[0];
	const buyCurrency = currency.filter((currencyItem) => currencyItem.id === item.to_asset_id)[0];
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}
	const [oneText, twoText] = splitText(item.pair || 'btc_eur');

	const handleDetailedViewRedirection = () => {
		dispatch(addDetailedQuery({ currentPage, perPage }));
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>

				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sell</p>
				<p className="td-name--flex">{!!sellCurrency && sellCurrency?.code.toUpperCase()}</p>
				<span className="td-more-info">{!!sellCurrency && sellCurrency?.name}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Buy</p>
				<p className="td-name--flex">{!!buyCurrency && buyCurrency?.code.toUpperCase()}</p>
				<span className="td-more-info">{!!buyCurrency && buyCurrency.name}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount Sold</p>
				<p>{!!sellCurrency && toFixedNumber(item.quantity, sellCurrency?.code, true)}</p>
				<span className="td-more-info">{!!sellCurrency && sellCurrency?.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount Received</p>
				<p>{!!buyCurrency && toFixedNumber(item.amount, buyCurrency.code, true)}</p>
				<span className="td-more-info">{!!buyCurrency && buyCurrency.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Exchange Rate</p>
				<p>{toFixedNumber(item.price_b2c2, 'crypto', true)}</p>
				<span className="td-more-info">{twoText.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{!!sellCurrency && toFixedNumber(item.fee, sellCurrency?.code, true)}</p>
				<span className="td-more-info">{!!sellCurrency && sellCurrency?.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Tx ID</p>
				<Link
					to={{
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						pathname: `${url}/${item.id}`,
						state: {
							txId: item.id,
							fromTradeUser: true,
							userId: item.user_id,
						},
					}}
					onClick={handleDetailedViewRedirection}
				>
					{item.id}
				</Link>
			</div>
		</div>
	);
};

export default TradeHistoryRow;
