import { FC, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

interface IPropsAccountTabs {
	userId: string;
}

const AccountTabs: FC<IPropsAccountTabs> = ({ userId }) => {
	const { url } = useRouteMatch();
	const tabsAccountRef = useRef<any>();
	const [isMouseDown, setIsMouseDown] = useState(false);
	const mouseCoords = useRef({
		startX: 0,
		startY: 0,
		scrollLeft: 0,
		scrollTop: 0,
	});
	const [isDragging, setIsDragging] = useState(false);

	const handleDragStart = (e: any) => {
		if (!tabsAccountRef.current) return;
		const slider = tabsAccountRef.current.children[0];
		const startX = e.pageX - slider.offsetLeft;
		const startY = e.pageY - slider.offsetTop;
		const { scrollLeft } = slider;
		const { scrollTop } = slider;
		mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
		setIsMouseDown(true);
		setIsDragging(false);
		// document.body.style.cursor = 'grabbing';
	};
	const handleDragEnd = (e: any) => {
		setIsMouseDown(false);
		if (!tabsAccountRef.current) return;
		// document.body.style.cursor = 'default';

		// Check if the movement was minimal (i.e., a click and not a drag)
		const slider = tabsAccountRef.current.children[0];
		const endX = e.pageX - slider.offsetLeft;
		const endY = e.pageY - slider.offsetTop;
		const diffX = Math.abs(endX - mouseCoords.current.startX);
		const diffY = Math.abs(endY - mouseCoords.current.startY);

		// If movement is small, it's considered a click
		if (diffX < 5 && diffY < 5) {
			setIsDragging(false);
		} else {
			setIsDragging(true);
		}
	};
	const handleDrag = (e: any) => {
		if (!isMouseDown || !tabsAccountRef.current) return;
		e.preventDefault();
		const slider = tabsAccountRef.current.children[0];
		const x = e.pageX - slider.offsetLeft;
		const y = e.pageY - slider.offsetTop;
		const walkX = (x - mouseCoords.current.startX) * 1.5;
		const walkY = (y - mouseCoords.current.startY) * 1.5;
		slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
		slider.scrollTop = mouseCoords.current.scrollTop - walkY;
		// console.log(walkX, walkY);
	};

	const handleClick = (e: any) => {
		if (isDragging) {
			// Prevent link click during drag
			e.preventDefault();
			e.stopPropagation();
		}
	};

	return (
		<div
			className="tabs-account__wrap"
			ref={tabsAccountRef}
			onMouseDown={handleDragStart}
			onMouseUp={handleDragEnd}
			onMouseMove={handleDrag}
		>
			<div className="tabs-account mb-0">
				<NavLink to={`${url}${ROUTES.AccountTabs.ACCOUNT}`} onClick={handleClick}>
					Account
				</NavLink>
				<NavLink
					to={{ pathname: `${url}${ROUTES.AccountTabs.BALANCES}`, state: { id: userId } }}
					onClick={handleClick}
				>
					Balances
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.DEPOSIT_HISTORY}`} onClick={handleClick}>
					Deposit History
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.WITHDRAWAL_HISTORY}`} onClick={handleClick}>
					Withdrawal History
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.TRANSFER_HISTORY}`} onClick={handleClick}>
					Transfer History
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.TRADE_HISTORY}`} onClick={handleClick}>
					Trade History
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.REFERRAL}`} onClick={handleClick}>
					Referrals
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.TRADING_LIMITS}`} onClick={handleClick}>
					Trade Limits
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.TRANSFER_LIMITS}`} onClick={handleClick}>
					Transfer Limits
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.PAYMENT_METHOD}`} onClick={handleClick}>
					Beneficiaries
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.TRADE_MANAGEMENT}`} onClick={handleClick}>
					Liquidity
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.FEE_MANAGEMENT}`} onClick={handleClick}>
					Fee Management
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.CURRENCIES_EMAILS}`} onClick={handleClick}>
					Currencies
				</NavLink>
				<NavLink to={`${url}${ROUTES.AccountTabs.REQUIRED_EMAILS}`} onClick={handleClick}>
					Emails
				</NavLink>
				{/* <NavLink to={`${url}${ROUTES.AccountTabs.REFERRAL_FEE}`} onClick={handleClick}>
					Referral Fee
				</NavLink> */}
			</div>
		</div>
	);
};

export default AccountTabs;
