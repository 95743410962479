/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import {
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	EPendingWithdrawalType,
	IPendingWithdrawal,
} from 'services/api/transactions/types';
import {
	putPendingWithdrawalApproveRequest,
	putPendingWithdrawalCancelRequest,
	putPendingWithdrawalCryptoApproveRequest,
	putPendingWithdrawalCryptoRejectRequest,
	putPendingWithdrawalNoteRequest,
	putPendingWithdrawalRejectRequest,
	putPendingWithdrawalStatusRequest,
} from 'redux/reducers/transactions/reducer';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import { roundingNumber } from 'services/utils/roundingNumber';
import { capitalizeWords, shortNotation } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import moment from 'moment';
import { getNameList } from 'country-list';
import { getPendingWithdrawal } from 'redux/reducers/transactions/selectors';
import { addDetailedQuery } from 'redux/reducers/transactionsFilters/reducer';
import { ICountry } from '../../../../tables/CreateUserTables/UserTable';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const STATUS_TO_EDIT_CRYPTO = [
	{
		id: 1,
		name: 'Approved',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
	},
];
const STATUS_TO_EDIT_FIAT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 4,
		name: 'Cancelled',
		value: EApiPooledDepositTransactionStatus.CANCELED,
	},
];

export interface IPendingWithdrawalTableRow extends IPendingWithdrawal {
	permission: boolean;
	assetType: EPendingWithdrawalType;
}

const PendingWithdrawalTableRow: FC<any> = ({
	id,
	status,
	created_at,
	asset,
	amount,
	type,
	user,
	permission,
	note,
	assetType,
	admin,
	tx_hash,
	admin_id,
	record_type,
	admin_requisite_id,
	fee,
	indicated_amount,
	user_id,
	gross_fee,
	net_fee,
	destination,
	address,
	bank_account,
}) => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const currentPage = useSelector(getPendingWithdrawal)?.current_page;
	const perPage = useSelector(getPendingWithdrawal)?.per_page;

	const [countries, setCountries] = useState<ICountry[]>([]);
	const [beneficiaryCountry, setBeneficiaryCountry] = useState<ICountry | null>(null);
	const [bankCountry, setBankCountry] = useState<ICountry | null>(null);

	useLayoutEffect(() => {
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useEffect(() => {
		if (bank_account?.beneficiary_country && bank_account?.bank_country) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			// fetch(
			// 	'https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code',
			// )
			// 	.then((response) => response.json())
			// 	.then((data) => {
			// 		setBeneficiaryCountry(
			// 			data.countries.filter(
			// 				(item: ICountry) => item.value === bank_account?.beneficiary_country,
			// 			)[0],
			// 		);
			// 		setBankCountry(
			// 			data.countries.filter((item: ICountry) => item.value === bank_account?.bank_country)[0],
			// 		);
			// 	});
			setBeneficiaryCountry(
				countries.filter((item: ICountry) => item.value === bank_account?.beneficiary_country)[0],
			);
			setBankCountry(
				countries.filter((item: ICountry) => item.value === bank_account?.bank_country)[0],
			);
		}
	}, [bank_account, countries]);

	const [date, time] = useFormatDateTime(created_at);
	const statusToEditArray =
		assetType === EPendingWithdrawalType.FIAT ? STATUS_TO_EDIT_FIAT : STATUS_TO_EDIT_CRYPTO;

	const filterStatusArrayToEdit = (currentStatus: EApiPooledDepositTransactionStatus | string) => {
		const statusFinal = currentStatus === 'pending' ? 'admin_pending' : currentStatus;
		return statusToEditArray.filter((el) => el.value !== statusFinal);
	};
	const findEditedStatus = (currentStatus: string) => {
		const statusFinal = currentStatus === 'pending' ? 'admin_pending' : currentStatus;
		return statusToEditArray.find((el) => el.value === statusFinal) || statusToEditArray[0];
	};

	const [editedNote, setEditedNote] = useState(note);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value || null);
	};

	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>({
		id: 0,
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
		name: 'Pending',
	});
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => setEditMode(true);
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		if (record_type === 'transaction_fiat') {
			const data: any = {
				admin_requisite_id,
				date: moment(new Date()).format('YYYY-MM-DD'),
				fee,
				indicated_amount,
				note: editedNote || '',
				status: editedStatus.value.toLowerCase(),
			};
			dispatch(putPendingWithdrawalStatusRequest({ type: assetType, id, payloadData: data }));
		} else if (record_type === 'transaction_crypto') {
			if (editedStatus.value === EApiPooledDepositTransactionStatus.FAILED) {
				dispatch(putPendingWithdrawalCryptoRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.REJECTED) {
				dispatch(putPendingWithdrawalCryptoRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.COMPLETED) {
				dispatch(putPendingWithdrawalCryptoApproveRequest({ type: assetType, id }));
			}
		} else {
			if (editedStatus.value === EApiPooledDepositTransactionStatus.FAILED) {
				dispatch(putPendingWithdrawalRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.REJECTED) {
				dispatch(putPendingWithdrawalRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.COMPLETED) {
				dispatch(putPendingWithdrawalApproveRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.CANCELED) {
				dispatch(putPendingWithdrawalCancelRequest({ type: assetType, id }));
			}
			if (editedNote !== note) {
				dispatch(
					putPendingWithdrawalNoteRequest({
						type: assetType,
						data: { transaction_id: id, note: editedNote || '' },
					}),
				);
			}
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedStatus(findEditedStatus(status));
		disableEditMode();
	};

	const convertStatusValue = (currentStatus: EApiTransactionStatus) => {
		switch (currentStatus) {
			case EApiTransactionStatus.FAILED:
				return 'Failed';
			case EApiTransactionStatus.CANCELED:
				return 'Failed';
			case EApiTransactionStatus.REJECTED:
				return 'Failed';
			case EApiTransactionStatus.ADMIN_PENDING:
				return 'Pending';
			case EApiTransactionStatus.PENDING_SIGNING:
				return 'Pending Signing';
			case EApiTransactionStatus.SUCCESS:
				return 'Completed';
			case EApiTransactionStatus.COMPLETED:
				return 'Completed';
			case EApiTransactionStatus.BROADCASTING:
				return 'Broadcasting';
			default:
				return `${currentStatus[0].toUpperCase()}${currentStatus.slice(1)}`;
		}
	};
	const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	let userId;
	if (user_id && !admin_id) {
		userId = user_id;
	}
	if (!user_id && admin_id) {
		userId = admin_id;
	}
	if (user_id && admin_id) {
		userId = user_id;
	}

	const handleDetailedRedirection = () => {
		currentPage && perPage && dispatch(addDetailedQuery({ currentPage, perPage }));
	};

	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				{user_id && (
					<>
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<Link to={`/user-management/${userId}/account`} onClick={handleDetailedRedirection}>
							{userId}
						</Link>
					</>
				)}
				{admin_id && <p>{admin_id}</p>}
			</div>
			<div className="td">
				<p className="td-hidden-name">Client Name</p>
				{user?.data?.first_name && (
					<>
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<Link to={`/user-management/${userId}/account`} onClick={handleDetailedRedirection}>
							{user?.data?.first_name} {user?.data?.last_name}
						</Link>
					</>
				)}
				{admin?.first_name && (
					<p>
						{admin?.first_name} {admin?.last_name}
					</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{asset.code.toUpperCase()}</p>
				<span className="td-more-info">{asset?.name}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Gross</p>
				{record_type === 'pool_transaction' ? (
					<>
						<p>{toFixedNumber(amount, asset.code, true)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				) : (
					<>
						<p>{toFixedNumber(gross_fee, asset.code, true)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{toFixedNumber(fee, asset.code, true)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Net</p>
				{record_type === 'pool_transaction' ? (
					<>
						<p>{toFixedNumber(amount, asset.code, true)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				) : (
					<>
						<p>{toFixedNumber(net_fee, asset.code, true)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>

			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p
					className={
						assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
					}
				>
					From: <span className="td-bold-text">{from}</span>
				</p>
				<p
					className={
						assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
					}
				>
					To: <span className="td-bold-text">{to}</span>
				</p>
			</div>

			{assetType === EPendingWithdrawalType.FIAT && (
				<div className="td">
					<p className="td-hidden-name">Destination</p>
					<p>
						<span className="tooltip-wrap">
							{bank_account?.bank_name || '-'}

							{bank_account?.bank_name && (
								<span className="tooltip-item">
									<span className="tooltip-item__title">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15"
											height="14"
											viewBox="0 0 15 14"
											fill="none"
										>
											<path
												d="M7.20003 12.8307C8.81085 12.8307 10.2692 12.1778 11.3248 11.1222C12.3804 10.0665 13.0334 8.60821 13.0334 6.9974C13.0334 5.38658 12.3804 3.92825 11.3248 2.8726C10.2692 1.81698 8.81085 1.16406 7.20003 1.16406C5.58922 1.16406 4.13088 1.81698 3.07524 2.8726C2.01962 3.92825 1.3667 5.38658 1.3667 6.9974C1.3667 8.60821 2.01962 10.0665 3.07524 11.1222C4.13088 12.1778 5.58922 12.8307 7.20003 12.8307Z"
												stroke="#5974FF"
												strokeLinejoin="round"
											/>
											<path
												d="M7.20036 3.71094C7.32692 3.71094 7.42952 3.81354 7.42952 3.9401C7.42952 4.06667 7.32692 4.16927 7.20036 4.16927C7.0738 4.16927 6.97119 4.06667 6.97119 3.9401C6.97119 3.81354 7.0738 3.71094 7.20036 3.71094Z"
												fill="#5974FF"
												stroke="#5974FF"
											/>
											<path
												d="M7.34603 9.91927V5.83594H7.05436H6.7627"
												stroke="#5974FF"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.3252 9.91406H8.36686"
												stroke="#5974FF"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
									<span className="tooltip tooltip--width-720 tooltip--bottom">
										<span className="bank-info">
											<span className="bank-info bank-info--title">{bank_account?.bank_name}</span>
											<span className="bank-info bank-info--list-wrapper">
												<span className="bank-info bank-info--list">
													<span className="bank-info bank-info--item">
														<span>Beneficiary Name</span>
														<p>{bank_account?.beneficiary_name}</p>
													</span>
													<span className="bank-info bank-info--item">
														<span>Beneficiary Address</span>
														<p>{bank_account?.beneficiary_address}</p>
													</span>
													<span className="bank-info bank-info--item">
														<span>Beneficiary Country</span>
														<p>{beneficiaryCountry?.label || '-'}</p>
													</span>
													<span className="bank-info bank-info--item">
														<span>Bank Name</span>
														<p>{bank_account?.bank_name}</p>
													</span>
												</span>
												<span className="bank-info-list bank-info--list">
													<span className="bank-info  bank-info--item">
														<span>Bank Address</span>
														<p>{bank_account?.bank_address}</p>
													</span>
													<span className="bank-info  bank-info--item">
														<span>Bank Country</span>
														<p>{bankCountry?.label || '-'}</p>
													</span>
													<span className="bank-info  bank-info--item">
														<span>SWIFT / BIC</span>
														<p>{bank_account?.swift_bic}</p>
													</span>
													<span className="bank-info  bank-info--item">
														<span>Account Number</span>
														<p>{bank_account?.account_number}</p>
													</span>
												</span>
												<span className="bank-info  bank-info--text">
													<span>Notes</span>
													<p>{bank_account?.note}</p>
												</span>
											</span>
										</span>
									</span>
								</span>
							)}
						</span>
						<span className="td-more-info">{bank_account?.account_number}</span>
					</p>
				</div>
			)}

			{assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<p className="td-hidden-name">Destination</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(destination || address?.address, 4, 4) || '-'}
						</span>
						{!!destination && (
							<CopyToClipboard text={destination.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
											fill="#5974FF"
										/>
									</svg>
									Copy
								</button>
							</CopyToClipboard>
						)}
						{!!address?.address && (
							<CopyToClipboard text={address?.address.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
											fill="#5974FF"
										/>
									</svg>
									Copy
								</button>
							</CopyToClipboard>
						)}
					</p>
				</div>
			)}
			{assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(tx_hash, 4, 4) || '-'}
						</span>
						{!!tx_hash && (
							<CopyToClipboard text={tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
											fill="#5974FF"
										/>
									</svg>
									Copy
								</button>
							</CopyToClipboard>
						)}
					</p>
				</div>
			)}
			{/* <div className="td">
				<p className="td-hidden-name">Admin Notes</p>
				{editMode ? (
					<div className="input">
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Notes"
								value={editedNote || ''}
								onChange={handleChangeEditedNote}
								className="input-item input-item--type-td"
							/>
						</div>
					</div>
				) : (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">{note ? `${note?.slice(0, 10) as string}...` : '-'}</p>
						<div className="tooltip-note">
							<p>{note || '-'}</p>
							<i />
						</div>
					</div>
				)}
			</div> */}
			{assetType === EPendingWithdrawalType.CRYPTO ? (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					{record_type === 'pool_transaction' ? (
						<p>{id}</p>
					) : (
						<Link
							to={{
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								pathname: `${path}/${id}`,
								state: { fromPendingWithdrawalCrypto: true, txId: id, userId: user_id },
							}}
							onClick={handleDetailedRedirection}
						>
							{id}
						</Link>
					)}
				</div>
			) : (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					{record_type === 'pool_transaction' ? (
						<p>{id}</p>
					) : (
						<Link
							to={{
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								pathname: `${path}/${id}`,
								state: { fromPendingWithdrawalFiat: true, txId: id, userId: user_id },
							}}
							onClick={handleDetailedRedirection}
						>
							{id}
						</Link>
					)}
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{/* {editMode && status === EApiTransactionStatus.ADMIN_PENDING ? ( */}
				{editMode ? (
					<TableItemSelect
						activeElement={editedStatus}
						selectArray={statusArrayToEdit}
						onChange={handleEditStatus}
					/>
				) : (
					<span
						className={
							status === EApiTransactionStatus.ADMIN_PENDING
								? 'pending-status'
								: `${String(status)}-status`
						}
					>
						{convertStatusValue(status)}
					</span>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn btn--icon btn--cancel"
							onClick={handleDisableEditMode}
						/>
						<button
							type="button"
							className="btn btn--icon btn--check"
							onClick={handleEdit}
							disabled={
								editedStatus.value === EApiPooledDepositTransactionStatus.ADMIN_PENDING &&
								note === editedNote
							}
						/>
					</div>
				)}
				{permission && !editMode && (
					<>
						{assetType === 'fiat' ? (
							<>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp */}
								<button
									type="button"
									className="btn btn--icon btn--edit"
									onClick={enableEditMode}
									// disabled={status !== EApiTransactionStatus.ADMIN_PENDING}
								></button>
							</>
						) : (
							<>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp */}
								<button
									type="button"
									className="btn btn--icon btn--edit"
									onClick={enableEditMode}
									// disabled={status !== EApiTransactionStatus.ADMIN_PENDING}
									disabled={
										status === EApiTransactionStatus.PENDING ||
										status === EApiTransactionStatus.BROADCASTING ||
										status === EApiTransactionStatus.PENDING_SIGNING
									}
								></button>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PendingWithdrawalTableRow;
