/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikValues } from 'formik';
import SelectDate from 'ui/SelectDate';
import InputFile from 'ui/Formik/InputFile/InputFile';
import WarningPopUp from 'layouts-elements/PopUp/WarningPopUp/WarningPopUp';
import * as yup from 'yup';
import { getPlainTextFromEditorState } from 'utils/getPlainText';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesState, getOneMessage } from 'redux/reducers/messages/selectors';
import {
	cloneMessageRequest,
	createMessageRequest,
	deleteFilesRequest,
	deleteMessageRequest,
	downloadFilesRequest,
	getOneMessageRequest,
	updateMessageRequest,
} from 'redux/reducers/messages/reducer';
import { getUsersRequest } from 'redux/reducers/users/reducer';
import { EApiMessagesType } from 'redux/reducers/messages/types';
import moment from 'moment';

import handleUploadIcon from 'ui/Formik/InputFile/config';
import uploadImage from 'ui/Formik/InputFile/config';
import { CloseFileIcon } from 'assets/inline-svg';
import Loading from 'layouts-elements/Loading/Loading';
import UserSearch from './UserSearch/UserSearch';
import ManageUsersTable from './ManageUsersTable';
import { TextEditor } from './TextEditor';

export interface ICreateMessageForm {
	closeForm: () => void;
	messageId: number | null;
	duplicate: boolean;
	setDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
	type: EApiMessagesType;
}

const CreateMessageForm: FC<ICreateMessageForm> = ({
	closeForm,
	messageId,
	duplicate,
	setDuplicate,
	type,
}) => {
	const message = useSelector(getOneMessage);
	const { loadingFiles, loading } = useSelector(getMessagesState);
	const dispatch = useDispatch();
	const [userIds, setUserIds] = useState<number[]>([]);
	const [fileList, setFileList] = useState<File[] | []>([]);
	const [sendNow, setSendNow] = useState(true);
	const [cloneMessage, setCloneMessage] = useState(false);

	const [openConfirmModal, setOpenConfirmModal] = useState(false);

	const [checked, setChecked] = useState(false);

	const [manageUsers, setManageUsers] = useState(false);

	const handleManageUsersToogle = () => {
		setManageUsers(!manageUsers);
		dispatch(getUsersRequest({ per_page: 999999, status: 'approved' }));
	};

	const handleCheckAllUsers = () => {
		setChecked(!checked);
		if (checked) {
			setUserIds(message?.users_ids || []);
		} else {
			setUserIds([]);
		}
	};

	const handleChangeUserId = (currentUserId: number, target?: boolean) => {
		if (target) {
			if (userIds.includes(currentUserId)) {
				return;
			}
			setUserIds([...userIds, currentUserId]);
		} else {
			setUserIds(userIds.filter((userId) => userId !== currentUserId));
		}
	};
	useEffect(() => {
		if (messageId) {
			dispatch(getOneMessageRequest({ messageId }));
		}
		setSendNow(Boolean(!messageId));
	}, [messageId, dispatch]);

	useEffect(() => {
		if (message && messageId) {
			setUserIds(message.users_all ? [] : message.users_ids);
			setChecked(Boolean(message.users_all));
		} else {
			setUserIds([]);
		}
	}, [message, messageId]);

	const initialValues = {
		title: message?.title || '',
		message: message?.message || '',
		date: message?.planned_on || '',
	};

	const validationSchema = yup.object().shape({
		title: yup
			.string()
			.required('Please enter Title')
			.max(150, 'Title is too long. Maximum 150 characters'),
		date: yup.string().test('required', 'Please select Date and time', (value) => {
			return value !== 'Invalid date';
		}),
		message: yup
			.string()
			.test('max', 'Message text is too long. Maximum 1500 characters', (value) => {
				return getPlainTextFromEditorState(value).length <= 1500;
			})
			.test('required', 'Please enter Message text', (value) => {
				return getPlainTextFromEditorState(value).length > 0;
			}),
	});

	const handleOpenConfirmModal = () => {
		setOpenConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setOpenConfirmModal(false);
	};

	const getTimezoneOffset = () => {
		return moment().format('Z');
	};

	const handleSubmit = (values: FormikValues) => {
		const formData = new FormData();

		fileList &&
			fileList.length &&
			fileList.forEach((file: File) => formData.append(`files[]`, file));

		!checked && userIds.forEach((id: number) => formData.append(`users[]`, String(id)));

		const requestBody = {
			title: values.title,
			message: values.message,
			...(sendNow
				? { sent: 1 }
				: { planned_on: moment(values.date).format('YYYY-MM-DD HH:mm:ss') }),
			...(checked && { usersAll: 1 }),
			...(message && { id: message.id }),
			...(!sendNow && { timezone: `UTC${getTimezoneOffset()}` }),
		};

		Object.keys(requestBody).forEach((key) => {
			const value = requestBody[key as keyof typeof requestBody];
			if (value !== undefined) {
				formData.append(key, value.toString());
			}
		});

		if (message?.id) {
			dispatch(
				updateMessageRequest({ body: formData, onCloseForm: closeForm, id: message.id, type }),
			);
		} else {
			dispatch(
				createMessageRequest({ body: formData, onCloseForm: closeForm, sent: sendNow, type }),
			);
		}
	};

	const handleDeleteMessage = () => {
		message && dispatch(deleteMessageRequest({ id: message?.id, onCloseForm: closeForm, type }));
	};

	const handleDeleteFile = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, url: string) => {
		event.stopPropagation();
		message && dispatch(deleteFilesRequest({ id: message?.id, files: [url] }));
	};

	const handleDownloadFile = (file: string) => {
		dispatch(downloadFilesRequest({ file: file.split('/')[0] }));
	};

	const handleDuplicateMessage = () => {
		setCloneMessage(!cloneMessage);
		setDuplicate(!duplicate);
		message?.id &&
			dispatch(cloneMessageRequest({ id: message?.id, timezone: `UTC${getTimezoneOffset()}` }));
	};

	const handleCloseForm = () => {
		if (cloneMessage && message) {
			dispatch(deleteMessageRequest({ id: message?.id, type }));
		}
		closeForm();
	};

	const isDisabled = (isValid: boolean, dirty: boolean) => {
		const tooManyFiles = fileList.length + (message?.files?.length || 0) > 5;
		const noRecipients = checked ? !checked : !userIds?.length;

		return (!isValid && dirty) || tooManyFiles || noRecipients;
	};

	return (
		<div>
			{!loading ? (
				<>
					<div className={manageUsers ? 'message-form--hidden' : 'message-form'}>
						<div className="title-block title-block--deposit">
							<button type="button" className="btn btn--icon btn--back" onClick={handleCloseForm} />
							<p className="title-message">{messageId ? message?.title : 'New Message'}</p>
							{messageId && <span className="title__message-id">Message ID:{message?.id}</span>}
						</div>

						<div className="message-form_filters">
							<UserSearch
								allUsers={checked}
								handleChangeUserId={handleChangeUserId}
								checkedUsers={userIds}
								onManageUsersOpen={handleManageUsersToogle}
								duplicate={duplicate}
							/>
							<button
								type="button"
								className="btn btn-primary btn--mw-180"
								disabled={checked}
								onClick={handleManageUsersToogle}
							>
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M15.2859 6.31865C15.8069 7.06585 16.1735 7.92873 16.3395 8.86102H18.3334V11.1338H16.3395C16.1735 12.0661 15.8069 12.9289 15.2859 13.6761L16.6962 15.0864L15.0891 16.6935L13.6788 15.2832C12.9316 15.8042 12.0687 16.1709 11.1365 16.3369V18.3307H8.86371V16.3369C7.93141 16.1709 7.06854 15.8042 6.32133 15.2832L4.91104 16.6935L3.30399 15.0864L4.71425 13.6761C4.19325 12.9289 3.82663 12.0661 3.66064 11.1338H1.66675V8.86102H3.66064C3.82663 7.92873 4.19325 7.06585 4.71425 6.31865L3.30399 4.90835L4.91104 3.30131L6.32133 4.71156C7.06854 4.19056 7.93141 3.82395 8.86371 3.65795V1.66406H11.1365V3.65795C12.0687 3.82395 12.9316 4.19056 13.6788 4.71156L15.0891 3.30131L16.6962 4.90835L15.2859 6.31865Z"
										stroke="white"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
									<path
										d="M10.0001 12.0807C11.1507 12.0807 12.0834 11.148 12.0834 9.9974C12.0834 8.84681 11.1507 7.91406 10.0001 7.91406C8.8495 7.91406 7.91675 8.84681 7.91675 9.9974C7.91675 11.148 8.8495 12.0807 10.0001 12.0807Z"
										stroke="white"
										strokeWidth="1.5"
										strokeLinejoin="round"
									/>
								</svg>
								Manage users
							</button>
							<div className="switch">
								<label className="switch__label">
									<input
										onChange={handleCheckAllUsers}
										name="users"
										checked={checked}
										type="checkbox"
										className="hidden"
										disabled={duplicate}
									/>
									To all users
									<span className="switch__toggler switch__toggler--message" />
								</label>
							</div>
						</div>
						<Formik
							initialValues={initialValues}
							enableReinitialize
							validationSchema={validationSchema}
							validateOnBlur
							validateOnChange
							onSubmit={handleSubmit}
						>
							{({
								values,
								errors,
								handleChange,
								setFieldValue,
								isValid,
								touched,
								handleBlur,
								setFieldTouched,
								dirty,
							}) => {
								return (
									<Form className="form form--type3 ">
										<p className="form-title">Message</p>
										<div className="input input--col-2">
											<div className="input--row">
												<p className="input__name">Title</p>

												<input
													className={`input-item ${
														errors.title && touched.title ? 'error-border' : ''
													}`}
													name="title"
													type="text"
													placeholder="Enter Title"
													value={values.title}
													onChange={handleChange}
													disabled={duplicate}
													onBlur={handleBlur}
												/>
												{errors.title && touched.title && (
													<div className="error-red">
														<div className="input-notify">
															<span className="input-notify__text">{errors.title}</span>
														</div>
													</div>
												)}
											</div>

											<div>
												<Field
													name="date"
													component={SelectDate}
													dateValue={message?.planned_on}
													type="message"
													onSwitch={setSendNow}
													duplicate={duplicate}
													cloneMessage={cloneMessage}
												/>
												{errors.date && dirty && (
													<div className="error-red">
														<div className="input-notify">
															<span className="input-notify__text">{errors.date}</span>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="input--row">
											<p className="input__name">Message text</p>
											<TextEditor
												setFieldValue={setFieldValue}
												value={values.message}
												touched={touched.message}
												disabled={duplicate}
												setTouched={setFieldTouched}
												error={errors.message}
											/>

											{errors.message && touched.message && (
												<div className="error-red">
													<div className="input-notify">
														<span className="input-notify__text">{errors.message}</span>
													</div>
												</div>
											)}
										</div>
										<div className="message__attachment--container">
											<p className="message__attachment--title">Attachments</p>
											<p className="input__name">Upload File</p>

											<InputFile
												fileList={fileList}
												setFileList={setFileList}
												disabled={duplicate}
											/>
											<p className="input__name">Attached Files</p>
											<div className="message-links">
												{loadingFiles && <Loading height={20} />}
												{message && message.files?.length
													? message.files.map((item: string) => (
															<div
																key={item}
																className="input-file-preview__item"
																onClick={() => handleDownloadFile(item)}
															>
																<img src={handleUploadIcon(item.split('.')[1])} alt="" />
																<span className="input-file-preview__name" title={item}>
																	{item}
																</span>
																{!duplicate && (
																	<span
																		className="input-file-preview__item__del"
																		onClick={(event) => handleDeleteFile(event, item)}
																	>
																		<CloseFileIcon />
																	</span>
																)}
															</div>
													  ))
													: 'No data'}
											</div>
										</div>

										<div className="message__btn--container">
											{duplicate ? (
												<>
													<button
														type="button"
														className="btn btn-primary btn--mw-180"
														onClick={handleCloseForm}
													>
														Close
													</button>
													<button
														type="button"
														className="btn btn-primary btn--mw-180"
														onClick={handleDuplicateMessage}
													>
														Duplicate
													</button>
												</>
											) : (
												<>
													<div className="message__btn--wrapper">
														<button
															type="submit"
															className="btn btn-primary btn--mw-180"
															disabled={isDisabled(isValid, dirty)}
														>
															{sendNow ? 'Send' : 'Schedule'}
														</button>

														<button
															type="button"
															className="btn-link btn--mw-180"
															onClick={closeForm}
														>
															Cancel
														</button>
													</div>
													{messageId && !duplicate && (
														<button
															type="button"
															className="btn btn-danger btn--mw-180"
															onClick={handleOpenConfirmModal}
														>
															Remove Message
														</button>
													)}
												</>
											)}
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
				</>
			) : (
				<Loading />
			)}
			{manageUsers && (
				<ManageUsersTable
					onCheckSingleUser={handleChangeUserId}
					onClose={handleManageUsersToogle}
					checkedUsers={userIds}
					disabled={duplicate}
					setUsers={setUserIds}
				/>
			)}
			<WarningPopUp
				open={openConfirmModal}
				closeModal={closeConfirmModal}
				title="Remove message"
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				message={`Are you sure you want to delete message ID ${message?.id}?`}
				confirmHandler={handleDeleteMessage}
			/>
		</div>
	);
};

export default CreateMessageForm;
