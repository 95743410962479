/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */
import WarningPopUp from 'layouts-elements/PopUp/WarningPopUp/WarningPopUp';
import moment from 'moment';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { deleteMessageRequest } from 'redux/reducers/messages/reducer';
import { EApiMessagesType, IMessage } from 'redux/reducers/messages/types';
import { getPlainTextFromEditorState } from 'utils/getPlainText';

interface IMessageTableRow {
	item: IMessage;
	type: EApiMessagesType;
	onEdit: (id: number) => void;
	onDuplicate: (id: number) => void;
	setPage: React.Dispatch<React.SetStateAction<number>>;
}

const MessagesTableRow: FC<IMessageTableRow> = ({ item, type, onEdit, onDuplicate, setPage }) => {
	const dispatch = useDispatch();
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const permissions = useSelector(getPermissions);

	const handleOpenConfirmModal = () => {
		setOpenConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setOpenConfirmModal(false);
	};

	const handleDeleteMessage = () => {
		dispatch(deleteMessageRequest({ id: item.id, type }));
		setPage(1);
	};

	const displayMessageText = () => {
		const plainText = getPlainTextFromEditorState(item.message);
		return plainText.length > 75 ? `${plainText.slice(0, 75)}...` : plainText;
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">
					{type === EApiMessagesType.PLANNED ? 'Scheduled at' : 'Timestamp'}
				</p>

				<p className="td-name--flex">{moment.utc(item.planned_on).format('DD MMM, YYYY')}</p>
				<span className="td-more-info">{moment.utc(item.planned_on).format('HH:mm:ss')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Message</p>
				<p className="td-name--flex">
					{item.title.length > 65 ? `${item.title.slice(0, 65)}...` : item.title}
				</p>
				<span className="td-more-info">{displayMessageText()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				{item.users_ids.join(', ').length < 35 ? (
					<p className="td-name--flex">{item.users_ids.join(', ')}</p>
				) : (
					<p className="td-name--flex">
						{item.users_all ? `To all users` : `Total ${item.users_ids.length} recepients`}
					</p>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons flex-e">
					{!!permissions?.[EPermissionNames.MESSAGES]?.editable && (
						<>
							{type === EApiMessagesType.PLANNED ? (
								<>
									<button
										type="button"
										className="btn btn--icon btn--edit"
										onClick={() => onEdit(item.id)}
									/>
									<button
										type="button"
										className="btn btn--icon btn--delete"
										onClick={handleOpenConfirmModal}
									/>
								</>
							) : (
								<button
									type="button"
									className="btn btn--icon btn--copy"
									onClick={() => onDuplicate(item.id)}
								/>
							)}
						</>
					)}
				</div>
			</div>
			<WarningPopUp
				open={openConfirmModal}
				closeModal={closeConfirmModal}
				title="Remove message"
				message={`Are you sure you want to delete message ID ${item.id}?`}
				confirmHandler={handleDeleteMessage}
			/>
		</div>
	);
};

export default MessagesTableRow;
