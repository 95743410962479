import { useEffect } from 'react';
import AccountTabView from './AccountTabView/AccountTabView';

const AccountTab = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return <AccountTabView />;
};

export default AccountTab;
